import { useParams } from "react-router-dom";
import Page from "components/Page";
import { Box, Button, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import {
  useBalance,
  useAccount,
  useConnect,
  useDisconnect,
  useSignMessage,
  useContractReads
} from 'wagmi'

import VoidriteABI from 'abi/Voidrite.json' // Make sure you import the ABI of your contract here

import { BigNumber, ethers } from 'ethers'
import { ConnectButton } from "@rainbow-me/rainbowkit";
import Connect from "components/Connect";
import { Avatar } from '@mui/material';
import React, { useEffect, useState } from "react";

import InfoCard from "components/InfoCard";
import theme from "theme";
import Terms from "components/Terms";
import { Type } from "react-toastify/dist/utils";


const StreamingPage = () => {
  
  return (
    <Page>
      <Box
        bgcolor="rgba(23, 23, 33, 0.85)"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 1.5,
          width: "100%",
          padding: "0.5rem",
          borderRadius: "3px",
        }}
      >
        <Typography variant="h1">
        Streaming Permission Statement
        </Typography>
        <Terms
        title=""
        text="We appreciate your interest in our metaverse and are excited to see creators like you bring it to life in unique ways. We understand the importance of platforms like YouTube, Twitch, and other streaming services in fostering our vibrant community."   
         />
           <Terms
        title=""
        text="Therefore, we hereby grant permission to all streamers and content creators to capture, broadcast, and monetize gameplay footage from within our metaverse on any online platforms. This includes, but is not limited to, live streaming, making Let's Play videos, walkthroughs, tutorials, reviews, and other forms of commentary or analysis."   
         />
                 <Terms
        title=""
        text="We only ask that you comply with the Terms of Service of the platforms you are streaming on, respect other players in the metaverse, and refrain from showcasing or promoting any activity that violates our community guidelines."   
         />
                 <Terms
        title=""
        text="So, whether you're exploring new realms, building innovative structures, or engaging in thrilling adventures, we're eager to see the content you create. Share your journey with us and the rest of the community. Let's continue to make our metaverse a place of creativity, fun, and mutual respect"   
         />
       
       </Box>
    </Page>
  );
};

export default StreamingPage;
