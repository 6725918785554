import { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Card, CardMedia, CardContent, Typography, CardActions, Button, Box } from '@mui/material';
import theme from 'theme';

interface NFTData {
  image: string;
  name: string;
  description: string;
  series: string;
  attributes: { trait_type: string; value: string }[];
  external_url: string;
}

const RadiantGiftGallery = () => {
  const [nftData, setNftData] = useState<NFTData[]>([]);
 
  function getRandomNumberBetween0And14() {
    return Math.floor(Math.random() * 15);
  }
   const [tokenId, setTokenId] = useState(getRandomNumberBetween0And14());
  // Example usage
  //const randomNumber = getRandomNumberBetween0And14();
  const fetchNFTData = async () => {
    const nfts: NFTData[] = [];
    for(let i = tokenId; i < tokenId + 3; i++) {
      try {
        const res = await axios.get(`https://metadata.sagetowers.com/rgift/${i}`);
        nfts.push(res.data);
      } catch (err) {
        console.error(err);
        //if (err.response && err.response.status === 404) {
         // console.log(`No NFT found for token id: ${i}`);
          break;
       // }
        //
      }
    }
    setNftData(nfts);
  };

  useEffect(() => {
    fetchNFTData();
  }, [tokenId]);

  const handleLoadMore = () => {
    if((tokenId + 3)>=16){
        setTokenId(0);     
    }
    else{
    setTokenId(tokenId + 3);
    }
  };
const tokenkek =  (tokenId>2) ?  (tokenId) : tokenId;
  return (
    <div>
      <Grid  container spacing={4}>
        {nftData.map((nft, index) => (
          <Grid item xs={12} lg={4} key={index}>
            <Card sx={{// maxWidth: "26vw", 
        color: "#41b3a3",
        boxShadow: "0px 0px 14px rgba(128, 226, 126, 0.9)", 
        backgroundColor: "rgba(23, 23, 33, 0.8)",
        '& img': {
            
            
            filter: 'drop-shadow(0px 0px 7px rgba(65, 179, 163, 0.9))',
            transition: 'filter 0.3s ease-in-out',
        
          },
    //    filter: 'drop-shadow(0px 0px 10px rgba(65, 179, 163, 0.9))',
  padding: 2,
       // height: "72vh",
        }}>
                <Typography gutterBottom variant="h1" fontSize="1.1rem"  mt={-0.5} mb={2} sx={{color:"theme.palette.primary"}} component="div">
                  {nft.name} #{ tokenkek + index }
                </Typography>
              <CardMedia
                component="img"
                height="80%"
                width="120"
                image={nft.image.replace('ipfs://', 'https://ipfs.io/ipfs/')}
                alt={nft.name}
              />
              <CardContent sx={{height: "28vh",}}>
            
                <Typography maxWidth={"100%"}  m={0.3} sx={{color:"theme.palette.secondary"}} mb={1} variant="h3" fontSize="0.8rem"   >
                  {nft.description}
                </Typography>
    <Box margin={0.2} sx={{ display: "flex",
        flexDirection: "column",
        justifyContent: "left",
        alignItems: "left", color: "#41b3a3", 
        
        
        }}>
               
                                                               
                {nft.attributes.map((attribute, index) => (
                  <Typography sx={{ 
                 
                  justifyContent: "left",
                  alignItems: "left", 
                  fontalign: "left",
                  
                  }} m={0.2} key={index} variant="h3"  fontSize="0.8rem"   >
                    <strong>{attribute.trait_type}:</strong> {attribute.value}
                  </Typography>
                ))}
                </Box>
              </CardContent>
              
            </Card>
          </Grid>
        ))}
      </Grid>
      <Button sx={{mt:2.25, mb:-1.0, color:"rgba(128, 226, 126, 1)"}} size='small' variant="contained" color="secondary" onClick={handleLoadMore}>
        Load More
      </Button>
    </div>
  );
};

export default RadiantGiftGallery;
