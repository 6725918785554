
/*
import * as React from 'react';
import { useDebounce } from 'use-debounce';
import {
  usePrepareSendTransaction,
  useSendTransaction,
  useWaitForTransaction,
} from 'wagmi';
import { utils } from 'ethers';

export function LLMMintButton() {
  const [to, setTo] = React.useState<string>('');
  const [debouncedTo] = useDebounce<string>(to, 500);

  const [amount, setAmount] = React.useState<string>('');
  const [debouncedAmount] = useDebounce<string>(amount, 500);
  const { config } = usePrepareSendTransaction({
    request: {
      to: debouncedTo,
      value: debouncedAmount ? utils.parseEther(debouncedAmount) : undefined,
    },
  });
  
  const { data, sendTransaction } = useSendTransaction(config);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  




  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        sendTransaction?.();
      }}
    >
      <input
        aria-label="Recipient"
        onChange={(e) => setTo(e.target.value)}
        placeholder="0xA0Cf…251e"
        value={to}
      />
      <input
        aria-label="Amount (ether)"
        onChange={(e) => setAmount(e.target.value)}
        placeholder="0.05"
        value={amount}
      />
      <button disabled={isLoading || !sendTransaction || !to || !amount}>
        {isLoading ? 'Sending...' : 'Send'}
      </button>
      {isSuccess && (
        <div>
          Successfully sent {amount} ether to {to}
          <div>
            <a href={`https://etherscan.io/tx/${data?.hash}`}>Etherscan</a>
          </div>
        </div>
      )}
    </form>
  );
}

export default LLMMintButton;
*/
import ReactDOM from 'react-dom';
import ConfettiExplosion, { ConfettiProps } from 'react-confetti-explosion';
import { useEffect, useState } from "react"
import { usePrepareContractWrite, useContractWrite, useWaitForTransaction, usePrepareSendTransaction, useSendTransaction } from "wagmi"
import { utils, BigNumber, ethers } from "ethers"
import {
  Box,
  Typography,
  Modal,
  Button,
  ButtonGroup,
  Card,
  keyframes,
  styled,
  CircularProgress,
  Grid,
  Link
} from "@mui/material"
import {MULTIPLY_GAS_LIMIT } from "config"
import { multiplyBigNumberByFloat, formatEtherFixed } from "utils/numbers"
import LLMABI from 'abi/LogicalMechanism.json'
import TokenView from "components/TokenView"
import MintingButtonEnabled from "components/MintingButtonEnabled"
import useWindowSize from "hooks/useWindowSize"
import MintingButtonDisabled from "./MintingButtonDisabled"
import useDebounce from "usehooks-ts/dist/esm/useDebounce/useDebounce"
import AddTokenButton from "./AddTokenButton"
const MINT_CONTRACT_ADDRESS = "0x3C856b95ADe474a321c92b5002436b7C57Fe5F96";
const provider = new ethers.providers.JsonRpcProvider("https://chain.sagetowers.com");
const contractAddress = "0x3C856b95ADe474a321c92b5002436b7C57Fe5F96";
const contractABI = LLMABI;
const contract = new ethers.Contract(contractAddress, contractABI, provider);

const rotate = keyframes`
  from {
    transform: rotate(0deg);
   
  }
  to {
    transform: rotate(360deg);
   
  }
 
`;
const wobble = keyframes`
  0% { transform: rotate(0deg); }
 25% { transform: rotate(2deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-2deg); }
  100% { transform: rotate(0deg); }
`;
const rotate2 = keyframes`

  from {
    transform: rotate(20deg);
  }
  to {
    transform: rotate(0deg);
  }
`;
const glow = keyframes`
  from {
    filter: drop-shadow(0px 0px 10px rgba(128, 226, 126, 0.9));
  }
  to {
    filter: drop-shadow(0px 0px 25px rgba(128, 226, 126, 1));
`;
const StyledCard = styled(Card)(({ theme }) => ({
  
  transition: "transform 0.3s ease-in-out",
  '&:hover': {
    transform: "scale(1.05)",
    boxShadow: "0px 0px 15px  rgba(92, 179, 163, 1)",
    backgroundColor: "rgba(0, 0, 0, 1)",
    filter: 'drop-shadow(0px 0px 15px rgba(65, 179, 163, 1))',
    '& img': {
      animation: `${wobble} 2.5s linear infinite, ${glow} 0.5s ease-in-out infinite`,
      transition: 'filter 0.15s ease-in-out',}
     //146	95	165
     // filter: 'drop-shadow(0px 0px 10px rgba(128, 226, 126, 0.9))',
     // transition: 'filter 0.15s ease-in-out',}
  },

  //width: 460,
  minHeight: 390,
  minWidth: 350,
  transform: "scale(1.05)",
    boxShadow: "0px 0px 15px  rgba(92, 179, 163, 1)",
    backgroundColor: "rgba(0, 0, 0, 1)",
    filter: 'drop-shadow(0px 0px 15px rgba(65, 179, 163, 1))',
    '& img': {
     //  animation: `${wobble} 2.5s linear infinite`,
     //146	95	165
      filter: 'drop-shadow(0px 0px 10px rgba(128, 226, 126, 0.9))',
      transition: 'filter 0.15s ease-in-out',}
}));

interface Props {
   
   
    mintPrice: any,
    ethPrice: any,
    balanceChange: any; // The change in the user's balance after the minting transaction. We only know the ETH transaction on the website, our Discord and MongoDB have
}


const LLMMintButton = ({ 
   
    
    mintPrice,
    ethPrice,
    balanceChange
    }: Props) => {
    const windowSize = useWindowSize()
    const [dialog, setDialog] = useState("")
    const [mintingPreview, setMintingPreview] = useState(false)
    
    const handleMintingPreviewOpen = () => setMintingPreview(true)
    const handleMintingPreviewClose = () => setMintingPreview(false)
    const [watchAddress, setwatchAddress] = useState("")
  
    const [amountToMint, setAmountToMint] = useState(999) // Assume we're minting 1 token initially

  
    const [isExploding, setIsExploding] = useState(false);

    const largeProps: ConfettiProps = {
      force: 0.9,
      duration: 4200,
      particleCount: 420,
      width: 1600,
      colors: ['#925fa5', '#80e27e', '#41b3a3', '#006FFF', '#33BAFE', '#925fa5', ]
    };
    
    const confetti = (
      <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
        <ConfettiExplosion width={1200} zIndex={1000} {...largeProps} />
      </div>
    );
  

    const [showConfetti, setShowConfetti] = useState(false);

const phases = [
  "Chrono-Adjusting Tempestuous Time Loops...",
  "Reversing Polarity of Netherstorm Flux...",
  "Configuring Ethereal Trade Routes...",
  "Ethereally Encrypting Consortium Transactions...",
  "Warping Reality Through Dimensional Portals...",
  "Reticulating Nether Splines...",
  "Unraveling Mystical Thread Concurrency...",
  "Interweaving Nether and Aether Flux Capacitance...",
];
//Make it a state variable
const [phase, setPhase] = useState(0);
//Make a function to cycle through the phases every 3 seconds
    const [bridgeStatus, setbridgeStatus] = useState(phases[0]);

//const [bridgeStatus, setBridgeStatus] = useState(phases[0]);

const cyclePhase = () => {
  if (phase < phases.length - 1) {
    setPhase(phase + 1);
  } else {
    setPhase(0); // corrected to 0
  }
  setbridgeStatus(phases[phase]);
  console.log(phase);
};

useEffect(() => {
  const randomInterval = Math.floor(Math.random() * 2000) + 2000;
  const intervalId = setInterval(cyclePhase, randomInterval);

  return () => clearInterval(intervalId); // Clears the interval when the component is unmounted
}, [phase]); // Re-run the effect when `phase` changes
  //}
// Call cyclePhase every 3 seconds without recursively setting new intervals



    //let  finalBuyprice = mintPrice ? mintPrice : BigNumber.from(0);
    /*
    const { config } = usePrepareContractWrite({
      abi: VoidriteMintABI,
      address: MINT_CONTRACT_ADDRESS,
      functionName: "mint",
      args: [amountToMint],
       overrides: {
        value:finalBuyprice.mul(amountToMint),
      },
    });
    
    let customRequest = config.request ? {
        data: config.request?.data,
        from: config.request?.from,
        gasLimit: multiplyBigNumberByFloat(config.request?.gasLimit, MULTIPLY_GAS_LIMIT),
        to: config.request?.to,
        value: config.request?.value
      } : undefined
    
      const { data, isError, isLoading, write } = useContractWrite({
        ...config,
        request: customRequest,
        onSuccess(data) {
          setDialog("Transaction pending...")
        }
    })
    
    const waitForTransaction = useWaitForTransaction({
      hash: data?.hash,
      confirmations: 1,
      onSuccess(data) {
        handleMintingPreviewOpen()
        setDialog("")  
        setTimeout(() => {
          handleMintingPreviewClose();
        }, 2500);

      }
    })
*/

//const mintPriceInEther: number = /* your mint price in Ether */;
//const amountToMint: number = /* number of tokens to mint */;
const [totalAmountInWei, setTotalAmountInWei] = useState(BigNumber.from(0));
function PriceRunner() {
  const kekr = amountToMint * mintPrice;
  console.log(kekr);
  const kekrRounded = kekr.toFixed(18); // Keep only 18 decimal places
  setTotalAmountInWei(ethers.utils.parseUnits(kekrRounded, 18));
  console.log(totalAmountInWei);
}
useEffect(() => {
  console.log("useEffect");
  PriceRunner();
  }, [amountToMint]);
//setAmountToMint(1000);
useEffect(() => {
  console.log("useEffect");
  setAmountToMint(1000)
  }, []);
  //.useEffect(() => {
   // console.log("useEffect");
    //if the mint window is up we close it
   // handleMintingPreviewClose();
   // PriceRunner();
  // }, [balanceChange]);
    const [lastTX, setlastTX] = useState("");
    const [sageTx, setsageTx] = useState("");
    const sendTransaction = async () => {
      try {
        PriceRunner();
        if(totalAmountInWei<BigNumber.from(1)){
          console.log("totalAmountInWei>BigNumber.from(1)");
         return;
        }
        // Connect to the provider (e.g., MetaMask)
        if (typeof window.ethereum !== 'undefined') {
          const provider = new ethers.providers.Web3Provider(window.ethereum as any);
          // rest of the code
          setlastTX("");
          setsageTx("");
          setwatchAddress("");
        //handleMintingPreviewOpen();
        
       // const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
  
        // Define the recipient address and amount to send (in Ether)'0x08D867C4ed584A53300a0127DB1abCEe8E70f854'
        const recipient = "0x7A9302622695De0724e03892BE56511789E0F987";//'sagetowers.eth';
        const amount = totalAmountInWei; // 0.1 Ether
  
        // Create and send the transaction
        const transaction = await signer.sendTransaction({
          to: recipient,
          value: amount,
        });
        handleMintingPreviewOpen();
        // Wait for the transaction to be mined
        const receipt = await transaction.wait();
        
          //receipt.from
        // Log the transaction hash
        console.log('Transaction hash:',     receipt.from);
        setwatchAddress(receipt.from);
        setlastTX(receipt.transactionHash);
      } else {
        console.error('Please install MetaMask!');
      }
      } catch (error) {
        console.error('Error sending transaction:', error);
      }
    };

const [lastMintedvalue, setlastMintedvalue] = useState(0);
useEffect(() => {
  if (!watchAddress) return;


  contract.on('Transfer', (from, to, value, event) => {
    if (to === watchAddress && from === '0x0000000000000000000000000000000000000000' )  {
     // console.log(`Mint detected! From: ${from}, To: ${to}, Value: ${value.toString()}`);
     //Check if we are in range 
     //if (value >= amountToMint * 0.90 && value <= amountToMint * 1.10) {
     // return true;
     console.log(`Mint detected! From: ${from}, To: ${to} in range, Value: ${value.toString()}, TX: ${event.transactionHash}`);
     setsageTx(event.transactionHash);
     setwatchAddress("");
     setlastMintedvalue(value.toString());
     //refreash balances
     handleBridgeDone();
    
   // } else {
      //return false;
     // console.log(`Mint detected! From: ${from}, To: ${to} but not in range, Value: ${value.toString()}`);
   // }
    }
   // console.log(`Mint detected! From: ${from}, To: ${to}, Value: ${value.toString()}`);
  });
  return () => {
    contract.removeAllListeners('Transfer');
  };
}, [watchAddress]);

//handleMintingPreviewOpen();

     // increase amountToMint by 1, but not more than maxPerTransaction
  const increaseAmountToMint = () => {
    setAmountToMint(prevAmount => prevAmount > 1 ? prevAmount + 1 : prevAmount);
    PriceRunner() ;
  }

  // decrease amountToMint by 1, but not lower than 1
  const decreaseAmountToMint = () => {
    setAmountToMint(prevAmount => prevAmount > 1 ? prevAmount - 1 : 1);
    PriceRunner() ;
  }

function handleAmountChange(e: React.ChangeEvent<HTMLInputElement>) {
    const inputValue = parseInt(e.target.value);
    if (inputValue >= 1 ) {
      setAmountToMint(inputValue);
      PriceRunner() ;
    }
}


const handleBridgeDone = () => {
  setShowConfetti(true);
  // Reset the confetti after some time
setIsExploding(true);
  setTimeout(() => {
    setIsExploding(false);
  }, 5000);
};
//etMintingPreview(true);
//We need to multiply the mint price by the amount of tokens we're minting then turn it from wei into ether

  return (
    <>
          {isExploding && ReactDOM.createPortal(confetti, document.body)}
     <ButtonGroup variant="text" color="secondary" >
          <Button variant="contained" sx={{ boxShadow: '0px 0px 0px 5px rgba(128, 226, 126, 0.0)' , zIndex:"2", width:"2vw",  mr:"-5px" ,fontSize:"1.5rem" }}   onClick={decreaseAmountToMint}>-</Button>
      
          <Box sx={{backgroundColor:"#232333", m:"0px", zIndex:"3"} }>
          <Box  sx={{ zIndex:"3" , display: 'flex', alignItems: 'center',mt:"0.5vh", ml:"0.5vh",mr:"0.5vh"}}>
     
    <input 
  type="number" 
  value={amountToMint} 
  onChange={handleAmountChange} 
  min="1" 
  style={{
    width: '65px', 
    fontSize: '14px',
    textAlign: 'center', 

    border: 'none', 
    backgroundColor: 'transparent', 
    color: 'inherit',
    appearance: 'textfield',
    MozAppearance: 'textfield',
    WebkitAppearance: 'textfield'
  }} 
/>

<Typography fontSize={"0.70rem"} fontWeight={400}>
    for  {(mintPrice * amountToMint).toFixed(10)} {'ETH'}
</Typography>
     
    </Box>
    

<Button
    variant="text"
    color="primary"
    disabled={!sendTransaction} 
    onClick={() => sendTransaction?.()}
    sx={{ 
      minWidth: "200px",
      paddingTop: 0.75,
      paddingRight: 0.45,
      paddingLeft: 0.45,
      paddingBottom: 0.75,
      boxShadow: "none",
      textTransform: "none",
    }}>
    
      <Typography fontFamily={'Cinzel'} fontSize={16} fontWeight={600} color={"#80e27e"}>
      {"Buy Logical Mechanism"}
      </Typography>
    </Button>



   </Box>
   <Button variant="contained"  sx={{ boxShadow: '0px 0px 0px 5px rgba(128, 226, 126, 0.0)' , zIndex:"2", width:"2vw",  ml:"-5px" ,fontSize:"1.5rem" }}     onClick={increaseAmountToMint}>+</Button>
        </ButtonGroup>
  
      
      <Box marginTop={1}>
        <Typography fontStyle="italic">
          {dialog}
        </Typography>
      </Box>

      <Modal
  open={mintingPreview}
  onClose={handleMintingPreviewClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
<Box

  sx={{
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {xs: '85%', sm: '85%', md:'660px', lg: '660px'},
    bgcolor: "none",
    border: "none",
    boxShadow: 0,
    padding: 5,
  }}
>
  <Box
    
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
      
    <StyledCard>
    
    <Box sx={ { width: {xs: '100%', sm: '100%', md:'55px', lg: '550px'}} }m={"2rem"} textAlign="center">
    <Typography color="#925fa5" id="modal-modal-title" variant="h1" fontSize="2.15rem">
      {lastTX === "" && sageTx ==="" ? "Netherizing ETH" : "Netherizing Done"}
    </Typography>
  </Box>
    <Box margin={"2rem"} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
     
    <Grid container spacing={1}  style={{ display: 'flex', justifyContent: 'center' }}> {/* Increase or decrease the number to change the spacing */}
  <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
    <img src="./media/icons/eth.png" alt="ETH Logo" width={"85px"} />
  </Grid>
  <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
    <img src="./media/icons/right.svg" alt="ETH Logo" width={"85px"} />
  </Grid>
  <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
   
      <img src="./media/icons/llmlogo.png" width={"85px"} alt="LLM Logo" />
    
  </Grid>
</Grid>

    </Box>
    <Box m={"2rem"} textAlign="center">
    {lastTX === "" ? (
      <Typography color="#925fa5" id="modal-modal-title" variant="h2" fontSize="1.0rem">
        {bridgeStatus}
      </Typography>
    ) : (
      <Box marginTop={2} textAlign="center">
        <Typography color="#925fa5" id="modal-modal-title" variant="h2" fontSize="1.0rem">
          <Link href={`https://etherscan.io/tx/${lastTX}`}>ETH Mainnet Complete- {lastTX.substr(0, 3) + '...' + lastTX.substr(-3)}</Link>
        </Typography>
       
      </Box>
    )}

        {lastTX !== ""  && sageTx === "" && (
     <Typography m={"1rem"} color="#925fa5" id="modal-modal-title" variant="h2" fontSize="1.0rem">
     Now Logically Nether-Synchronizing Mechanisms...
          
         </Typography>
    ) } 
    
    {lastTX !== ""  && sageTx !== "" && (
      <Box m={"1rem"} sx={{  display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center", }} textAlign="center">
        <Typography color="#925fa5" id="modal-modal-title" variant="h2" fontSize="1.0rem">
          <Link href={`https://scan.sagetowers.com/tx/${sageTx}`}>Sage Towers Complete- {sageTx.substr(0, 6) + '...' + sageTx.substr(-4)}</Link>
        </Typography>
        <Typography sx={{mt:"1.2rem"}} color="#925fa5" id="modal-modal-title" variant="h1" fontSize="1.8rem">
          LLM gained: {ethers.utils.formatUnits(lastMintedvalue, 18)}
         
        </Typography>
      </Box>
    )}

 
  </Box>
 
</StyledCard><Typography sx={{mt:"0.60rem"}} color="#000000" id="modal-modal-title" variant="body1" fontSize="0.7rem">
        This window is purely for your convenience. You can close it at any time.
        </Typography>
  </Box>
</Box>

</Modal>

    </>
  )
}

export default LLMMintButton
