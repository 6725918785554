import { Card, CardContent, CardHeader, Avatar, Typography, useTheme, styled, Box, Tooltip } from "@mui/material";
import RadiantGiftMintButton from "components/RadiantGiftMintButton";
import { BigNumber, ethers } from "ethers";
import { LinearProgress ,linearProgressClasses } from "@mui/material";

interface Props {
  isConnected: boolean,
//  mintOpen: boolean,
  mintPrice: BigNumber,
 // maxPerTransaction: number,
  currentSupply: number,
 // maxSupply: number
 userAddy: string,
 random:string
}

const StyledCard = styled(Card)(({ theme }) => ({
  transition: "transform 0.3s ease-in-out",
  '&:hover': {
    transform: "scale(1.05)",
    boxShadow: "0px 0px 15px  rgba(65, 179, 163, 1)",
    backgroundColor: "rgba(0, 0, 0, 1)",
    filter: 'drop-shadow(0px 0px 15px rgba(65, 179, 163, 1))',
    '& img': {
      width: '230px',
      objectFit: 'contain',
      filter: 'drop-shadow(0px 0px 10px rgba(128, 226, 126, 0.9))',
      transition: 'filter 0.15s ease-in-out',}
  },
  height: 480,
  minHeight: 480,
  minWidth: 260,
  backgroundColor: "rgba(0, 0, 0, 0.05)",
  color: theme.palette.secondary.contrastText, 
  boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.4)",
  '& img': {
    width: '230px',
    objectFit: 'contain',
    filter: 'drop-shadow(0px 0px 10px rgba(128, 226, 126, 0.3))',
    transition: 'filter 0.3s ease-in-out',

  }
}));

const RadiantGiftMintCard = ({ isConnected,  mintPrice, currentSupply, userAddy, random }: Props) => {
  const formattedPrice = mintPrice ? ethers.utils.formatUnits(mintPrice) : null;
  //const mintingStatus = mintOpen ? "Enabled" : "Disabled";
  const theme = useTheme();

  
 // const progress = (currentSupply / maxSupply) * 100;
/*
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 18,
    borderRadius: 99,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.secondary.dark,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 99,
      backgroundColor: theme.palette.primary.main
    },
  }));

*/
  return (
    <StyledCard>
        
        <CardHeader
      sx={{ backgroundColor: "rgba(0, 0, 0, 0.0)", color: theme.palette.primary.main }}    
        subheader={   <Typography pt={0.2} variant="h4"  style={{fontSize:"1.2rem"}} component="div">
     AI Generated Metaverse Items
      </Typography>}  
        title={<Typography style={{fontSize:"2.45rem"}}  variant="h1">Radiant Gifts</Typography>}
      />
          <img src="media/gifts.png" alt="RadiantGift" style={{ width: '230px', objectFit: 'contain' }}/>
        
      <CardContent sx={{ m:"-1vh"}} >     <Typography p={0.4}  variant="body1" style={{fontSize:"0.75rem"}}  component="p">
       No approval required! 100 LLM will be automatically transferred from your wallet to the contract.
        </Typography>
     <Box mb={"2vh"} mt={"2vh"} mr={"4rem"}  ml={"4rem"}> 
       <RadiantGiftMintButton  isConnected={isConnected} mintPrice={mintPrice} UserAddy= {userAddy} randomString={random} />
     </Box>
    

      
        
        
     
      </CardContent>
    </StyledCard>
  );
};

export default RadiantGiftMintCard;

