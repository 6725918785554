import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, useTheme, Box, Tooltip } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Terms from './Terms';


interface TermsOfServiceDialogProps {
  open: boolean;
  handleClose: () => void;
}

export const TermsPopup: React.FC<TermsOfServiceDialogProps> = ({ open, handleClose }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [acceptButtonVisible, setAcceptButtonVisible] = useState(false);
    const contentRef = useRef<HTMLDivElement | null>(null);
    const primaryColor = theme.palette.primary.main;
    const secondaryContrastTextColor = theme.palette.secondary.dark;
    
    useEffect(() => {
        const checkIfScrolledToBottom = () => {
          if (contentRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
            const scrollPosition = scrollTop + clientHeight;
            const scrollLimit = scrollHeight * 0.9;  // 90% of the scroll height
      
            console.log('ScrollTop:', scrollTop);
            console.log('ScrollHeight:', scrollHeight);
            console.log('ClientHeight:', clientHeight);
            console.log('ScrollPosition:', scrollPosition);
            console.log('ScrollLimit:', scrollLimit);
      
            if (scrollPosition >= scrollLimit) {
              setAcceptButtonVisible(true);
            }
          }
        };
      
        if (contentRef.current) {
          contentRef.current.addEventListener('scroll', checkIfScrolledToBottom);
        }
      
        return () => {
          if (contentRef.current) {
            contentRef.current.removeEventListener('scroll', checkIfScrolledToBottom);
          }
        };
      }, [open]);
      
  
    useEffect(() => {
      if (!open) {
        setAcceptButtonVisible(false);
      }
    }, [open]);
  return (
    <Dialog scroll='paper' open={open} onClose={handleClose} fullScreen={fullScreen}>
       <DialogTitle sx={{backgroundColor: secondaryContrastTextColor , color: primaryColor}}>
       Terms of Service- Scroll to continue</DialogTitle>
      <DialogContent ref={contentRef} sx={{ backgroundColor: secondaryContrastTextColor , color: primaryColor}}>
        { <Box
        bgcolor="rgba(23, 23, 33, 0.85)"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 1.5,
          width: "100%",
          padding: "0.5rem",
          borderRadius: "3px",
        }}
      >
        <Typography variant="h1">
            Terms of Service
        </Typography>
        <Terms
        title="1. Introduction"
        text="Welcome to Sage Towers (`We`, `Us`, or `Our`). This document serves as a legally binding agreement (`Terms and Conditions`) between you and Sage Towers concerning your use of our AI chatbot service (`Service`). By using our Service, you agree to these Terms and Conditions in full."   
         />
           <Terms
        title="2. Registration and Access"
        text="You must be at least 18 years old to use the Services. You must provide complete and accurate data to register the account. You may not make your account open to others outside your organization, and you are responsible for all activities that occur with your account."   
         />
                 <Terms
        title="3. Privacy"
        text="Your privacy is critically important to us. Our Privacy Policy explains how we treat your personal data and protect your privacy when you use our Service. By using our Service, you agree that Sage Towers can use your data in accordance with our Privacy Policy."   
         />
                 <Terms
        title="4. Intellectual Property"
        text="Unless otherwise stated, Sage Towers and/or its licensors own the intellectual property rights for all material on Sage Towers. All intellectual property rights are reserved."   
         />
                 <Terms
        title="5. OpenAI Usage"
        text="Sage Towers utilizes OpenAI API. In accordance with OpenAI's use case policy, any information you provide while interacting with the Service is used to fulfill your request and improve the Service."   
         />
                 <Terms
        title="6. Restrictions"
        text="You are expressly and emphatically restricted from all of the following:

        Misusing our Service by knowingly introducing viruses, trojans, worms, or other material that is malicious or technologically harmful.
        Attempting to duplicate, reproduce, copy, sell, resell or exploit any portion of the Service, or access to the Service without express written permission by us."   
         />
                 <Terms
        title="7. Limitation of Liability"
        text="In no event shall Sage Towers, nor any of its officers, directors and employees, be held liable for anything arising out of or in any way connected with your use of this Service, whether such liability is under contract, tort or otherwise, and Sage Towers, including its officers, directors and employees shall not be liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Service."   
         />
                 <Terms
        title="8. Indemnification"
        text="You hereby indemnify to the fullest extent Sage Towers from and against any and all liabilities, costs, demands, causes of action, damages and expenses (including reasonable attorney’s fees) arising out of or in any way related to your breach of any of the provisions of these Terms."   
         />
                 <Terms
        title="8. Revisions and Errata"
        text="We reserve the right to modify these terms from time to time at our sole discretion. Therefore, you should review this page periodically. Your continued use of the Service after we post any modifications to the Terms on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Terms."   
         />
    
       
       </Box>}
        <Typography variant="body1" sx={{pb:"2vh"}}>
          By accepting these terms of service, you agree to above and understand the following:
        </Typography>
        <Typography variant="body2">
        The content and information provided on this website, including links to external sites and information relating to the metaverse, cryptocurrencies, and AI, is provided for informational purposes only and should not be construed as financial or investment advice. Before making any decisions related to transactions within our metaverse or involving cryptocurrencies, users should consult with a qualified financial professional and consider their own individual financial circumstances and risk tolerance. We make no representations or warranties, express or implied, as to the accuracy, completeness, or timeliness of the information provided on this site, and we disclaim any responsibility for any loss or damage that may arise directly or indirectly from the use of such information.
        </Typography>
        <Box sx={{ justifyItems:"center",
        mt: "3vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",}}>
        <Button  onClick={handleClose}  sx={{ justifyItems:"center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
      
      minWidth: "250px",
      paddingTop: 1.75,
      paddingRight: 1.45,
      paddingLeft: 1.45,
      paddingBottom: 1.75,
      boxShadow: "0px 0px  15px rgba(128, 226, 126, 0.3)",
      textTransform: "none",
    }}>
      <Typography  fontSize={26} align='center' fontWeight={600}  color={"#80e27e"}>
        Yes, I agree to the terms of service.
      </Typography>
      </Button></Box>
      </DialogContent>
      

    </Dialog>
  );
};
