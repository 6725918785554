
import Page from "components/Page"
import SpaceDetails from "components/SpaceDetails"

const SnapshotPage = () => {
  return (
    <Page>
      <SpaceDetails></SpaceDetails>
    </Page>
  )
}

export default SnapshotPage