import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useTheme from "@mui/material/styles/useTheme";
import { TransitionProps } from '@mui/material/transitions';
import { 
    Box,//,
    Typography
   // Link,
   // AppBar,
   // Toolbar,
   // Drawer,
    //IconButton,
 //   List,
   //// ListItem,
  //  ListItemButton,
  //  ListItemText,
   // Menu,
   // MenuItem
  } from "@mui/material"
const DropdownMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMouseEnter = (event : any) => {
    setAnchorEl(event.currentTarget);
    document.body.style.overflow = 'auto';  // This
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };
  const handleMenuEnter = () => {
    document.body.style.overflow = 'auto';  // This will ensure scrollbar remains
  };
  return (
    <Box m="1rem"sx={{alignItems:"left", alignContent:"left", backgroundColor: "rgba(0, 0, 0, 0.00)"}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>     
      <Typography variant="h1"  sx={{fontSize:"1.0rem", alignItems:"right", alignContent:"right"}} onMouseDown={handleMouseEnter}>
        Items
      </Typography>
      <Menu  
        anchorEl={anchorEl}
        sx={{ backgroundColor: "rgba(0, 0, 0, 0)",   boxShadow: "0px 0px  25px rgba(128, 226, 126, 0.5)", 
        borderRadius: "2",}}
        open={Boolean(anchorEl)}
        onClose={handleMouseLeave}
        TransitionProps={{ onEntering: handleMenuEnter }} // use TransitionProps here
        anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
        MenuListProps={{ onMouseLeave: handleMouseLeave }} // ensure menu closes if mouse leaves the menu list
        PaperProps={{
          elevation: 0,  // remove shadow
          sx: {  backgroundColor: "rgba(23, 23, 33  , 0.95)",
          boxShadow: "0px 0px  25px rgba(128, 226, 126, 0.5)", 
          borderRadius: "2",
            
            mt: 1, // offset top
            '& .MuiAvatar-root': {
              width: 28,
              height: 28,
              ml: 0,
              mr: 0,
            },
            
          },
        }}
      >

        <MenuItem   sx={{ '&:hover': {backgroundColor: '#232333'}}}  onClick={handleMouseLeave} component={Link} to="/radiantgifts">Radiant Gifts</MenuItem>
        <MenuItem sx={{ '&:hover': {backgroundColor: '#232333'}}}  onClick={handleMouseLeave} component={Link} to="/voidrite">Voidrite</MenuItem>
      </Menu>
</Box>
  );
};

export default DropdownMenu;
