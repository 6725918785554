import { ConnectButton } from "@rainbow-me/rainbowkit"
import Box from "@mui/material/Box"

const Connect = () => {
  return (
    <Box mt={"-0.17rem"}>
      <ConnectButton 
        label="Connect"
        chainStatus="icon"
        accountStatus={{
          smallScreen: "avatar",
          largeScreen: "avatar"
        }}
        showBalance={false}
      />
    </Box>
  )
}

export default Connect
