import { useState } from "react"
import { 
  Box,
  Link,
  AppBar,
  Toolbar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  ButtonGroup
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import Connect from "components/Connect"
import { Typography } from "@mui/material"
import Select from 'react-dropdown-select';
import { useNavigate } from 'react-router-dom';
import DropdownMenu from "./DropdownMenu"
import DropdownMenuTokens from "./DropDownMenuTokens"
import { CustomConnectButton } from "./CustomConnectButton"
const options = [
  { label: 'Project 1', value: '/project1' },
  { label: 'Project 2', value: '/project2' },
];



const Header = () => {
  const navigate = useNavigate();
  const [leaveTimer, setLeaveTimer] = useState<any>(null);
  const handleProjectsMouseOver = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    clearTimeout(leaveTimer);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuMouseLeave = () => {
    const timer: any = setTimeout(() => {
      setAnchorEl(null);
    }, 12500); // Delay in milliseconds
    setLeaveTimer(timer);
  };
  
  
  const handleProjectsMouseOut = () => {
    const timer = setTimeout(() => {
      setAnchorEl(null);
    }, 500); // Delay in milliseconds
    setLeaveTimer(timer);
  };
  
const items = [
  {
    label: "Game",
    url: "/game",
    enabled: true,
    target: "_self"
  },
  {
    label: "Docs",
    url: "https://docs.sagetowers.com/sage-towers/",
    enabled: true,
    target: "_blank"
  },
  {
    label: "Scan",
    url: "https://scan.sagetowers.com/",
    enabled: true,
    target: "_blank"
  },
  {
    label: "Bridge",
    url: "/bridge",
    enabled: true,
    target: "_self"
  }, 
  {
    label: "Blog",
    url: "https://sagetowers.medium.com/",
    enabled: true,
    target: "_blank"
  }, 
 
  {
    label: "Profile",
    url: "/profile",
    enabled: true,
    target: "_self"
  },
  {
    label: "~ Items ~",
    url: "/",
    enabled: false,
    target: "_self"
  },
  {
    label: "Voidrite",
    url: "/voidrite",
    enabled: true,
    target: "_self"
  },
  {
    label: "Radiant Gift",
    url: "/radiantgifts",
    enabled: true,
    target: "_self"
  },
  {
    label: "~ Tokens ~",
    url: "/",
    enabled: false,
    target: "_self"
  },
  {
    label: "Logical Mechanism",
    url: "https://buy.stripe.com/4gw6qp8iSfFo7NCfYY",
    enabled: true,
    target: "_self"
  }
 
]


const handleClose = () => {
  setAnchorEl(null);
};
const [projects, setProjects] = useState([
  {label: "Project 1", url: "/project1"},
  {label: "Project 2", url: "/project2"},
  // Add more projects as needed
]);

  const [mobileOpen, setMobileOpen] = useState(false)
const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const projectsOptions = projects.map((project) => ({
    label: project.label,
    value: project.url,
  }));
  const drawer = (
    <Box background-color={"#232333"} onClick={handleDrawerToggle} sx={{textAlign: "center", backgroundColor:"#232333"} }>
      <List dense>
        {items.map((item) => (
          <ListItem sx={{"&:hover": {backgroundColor: "#232333"}}} key={item.label} disablePadding>
            <ListItemButton component={Link} href={item.url} target={item.target} sx={{textAlign: "left", pointerEvents: item.enabled ? "auto" : "none"}}>
              <ListItemText primary={item.label} primaryTypographyProps={{fontSize: 18, fontWeight: 600, color: item.enabled ? "primary" : "lightgrey"}}/>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )
  return (
    <Box sx={{width: "100%",  display: "flex", justifyContent: "center", backgroundColor: "#232333 "}}>
      <AppBar component="nav"  position="sticky" elevation={1} sx={{backgroundColor: "#232333 ", boxShadow: 0, zIndex: 100}}>
        <Toolbar sx={{width: "100%", height:"30px", display: "flex", margin: "auto", justifyContent: "space-between", backgroundColor: "#232333 "}}>
          <Box sx={{display: "flex", backgroundColor: "#232333 ", justifyContent: "center", alignItems: "center"}}>
            <IconButton
              color="default"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{mr: 2, display: {sm: "none"}}}>
              <MenuIcon/>
            </IconButton>
            <Box sx={{paddingRight: "30px", width:'16vw', display: {xs: "none", sm: "none", md: "flex", lg:"flex" }}}>
              <Link href="/" sx={{display: "flex", width:'16vw'}}>
              <img src="media/logo.png" alt="Sage Towers" width={'100%'}  />
              </Link>
            </Box>
            <Box sx={{paddingRight: "1px", width:'40vw', display: {xs: "flex", sm: "flex", md: "none", lg:"none" }}}>
              <Link href="/" sx={{display: "flex", width:'40vw'}}>
              <img src="media/logo.png" alt="Sage Towers" width={'50%'}  />
              </Link>
            </Box>
       
    <Box sx={{display: {xs: "none", sm: "block"}, alignItems:"center", alignContent:"center"}}>
      <ButtonGroup sx={{alignItems:"center", alignContent:"center"}} variant="text" aria-label="text button group">
      <Link pr="1.25rem" pl="1.25rem" variant="h1" href="/game" sx={{fontSize:"1.0rem", alignItems:"center", alignContent:"center", textDecoration: 'none' }}>
        Play Now
      </Link>
    <Link pr="1.25rem" pl="1.25rem" variant="h1" href="https://docs.sagetowers.com/"  sx={{fontSize:"1.0rem", alignItems:"center", alignContent:"center", textDecoration: 'none' }}>
        Docs
      </Link>
     
    
      <Link pr="1.25rem" pl="1.25rem" variant="h1" href="https://sagetowers.medium.com/"   sx={{fontSize:"1.0rem", alignItems:"center", alignContent:"center", textDecoration: 'none' }}>
        Blog
      </Link>

     
    <DropdownMenu/> 
 <DropdownMenuTokens/> 
      <Link pr="1.25rem" pl="1.25rem" variant="h1" href="/bridge"  sx={{fontSize:"1.0rem", alignItems:"center", alignContent:"center", textDecoration: 'none' }}>
        Bridge
      </Link>
      <Link pr="1.25rem" pl="1.25rem" variant="h1" href="https://scan.sagetowers.com/"  sx={{fontSize:"1.0rem", alignItems:"center", alignContent:"center", textDecoration: 'none' }}>
        Scan
      </Link>

      <Link pr="1.25rem" pl="1.25rem" variant="h1" href="/profile" sx={{fontSize:"1.0rem", alignItems:"center", alignContent:"center", textDecoration: 'none' }}>
        Profile
      </Link>

     </ButtonGroup>

    </Box>
          </Box>
          <Box sx={{ display: {xs: "none", sm: "none", md: "flex", lg:"flex" },  flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",}}>
            <Box>
       
          <Link href="https://discord.gg/sagetowers" target="_blank" sx={{mt:3.75, mr:4}} >
        <img  src="media/icons/discord.svg" alt="Discord" height='18px' width='18px' />
        </Link>
        
          <Link href="https://twitter.com/sagetowersdao" target="_blank" sx={{ mt:3.75, mr:4}} >
        <img src="media/icons/twitter.svg" alt="Twitter" height='18px' width='18px' />
        </Link>

        <Link href="https://github.com/Sage-Towers" target="_blank" sx={{ mt:3.75, mr:2.5}} >
        <img src="media/icons/github.svg" alt="Twitter" height='18px' width='18px' />
        </Link>
        
        </Box>
          <Box>
            
          <Connect/>
          </Box>
          </Box>
          <Box sx={{ display: {xs: "flex", sm: "flex", md: "none", lg:"none" },  gap: 1  ,  flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",}}>
            <Box>
            <Connect/>
          </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} ModalProps={{keepMounted: true}}
          sx={{
            
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              backgroundColor:"#232333",
              boxSizing: "border-box",
              width: 240
            }
          }}
        >     
          {drawer}
          <Box marginTop={'2vh'} sx={{ display: {xs: "flex", sm: "flex", md: "none", lg:"none" },  gap: 5  ,  flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",}}>
            <Box>
           
          <Link href="https://discord.gg/sagetowers" target="_blank" sx={{ m:3}} >
        <img  src="media/icons/discord.svg" alt="Discord" height='28px' width='28px' />
        </Link>
        
          <Link href="https://twitter.com/sagetowersdao" target="_blank" sx={{ m:3}} >
        <img src="media/icons/twitter.svg" alt="Twitter" height='28px' width='28px' />
        </Link>
        <Link href="https://github.com/Sage-Towers" target="_blank" sx={{ m:3}} >
        <img src="media/icons/github.svg" alt="Twitter" height='28px' width='28px' />
        </Link>
        </Box>
        </Box>
        </Drawer>
      </Box>
    </Box>
  )
}

export default Header