import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';


interface Props {
    title: string,
    text: string,
    imageUrl: string,
  }
  // rgba(128, 226, 126, 0.3)
const InfoCard = ({ title, text, imageUrl  }: Props) => {
  const theme = useTheme();

  return (
    <Box sx={{ marginBottom: theme.spacing(3)}}>
      <Card  sx={{ 
        padding: "2vw",
      
        backgroundColor: "rgba(0, 0, 0, 0.0)", 
        color: theme.palette.secondary.contrastText, 
        boxShadow: "0px 0px 10px rgba(65, 179, 163, 0.0)", 
      }}>
        { imageUrl != "none" &&(
        <CardMedia
          component="img"
          height="350px"
          image={imageUrl}
          sx={{  boxShadow: "0px 0px  25px rgba(128, 226, 126, 0.5)", borderRadius: "2"}}
          alt={title}
        />)}
        <CardContent>
          <Typography variant="h1" component="div">
            {title}
          </Typography>      
          <Typography variant="body1" color="text.secondary.contrastText">
            {text}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

export default InfoCard;
