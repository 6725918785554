import React from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Marquee from 'react-marquee-slider';

import Header from './Header';
import VideoBackground from '../utils/VideoBackground';
import Footer from './Footer';
import { Height } from '@mui/icons-material';


interface Props {
  children: React.ReactNode;
}

const Page = ({ children }: Props) => {
  const marqueeItems = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h6">Check out our amazing offers!</Typography>
    </Box>
  );

  /*
        <Box  sx={{
    backgroundImage:
   
    'linear-gradient(4.25turn, #41b3a3, #232333, #41b3a3)',
    padding: 1,
  }}>
        <Marquee
          velocity={20}
          direction="rtl"
          scatterRandomly={false}
          resetAfterTries={10}
          onInit={() => {}}
          onFinish={() => {}}
        >
          {Array.from({ length: 5 }, (_, id) => (
            <Box key={`marquee-item-${id}`} sx={{ paddingLeft: 2 }}>
              {marqueeItems}
            </Box>
          ))}
        </Marquee>
        </Box>
        */

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <VideoBackground videoSrc={'./media/bg/front.mp4'}>
        <Header />
 
        <main>
          <Container  sx={{ padding: 2, minHeight:"88vh",}}>{children}</Container>
        </main>
        <footer>
        
        <Footer />
       </footer>
      </VideoBackground>
      
    </Box>
  );
};

export default Page;
