import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Box, Typography, Link, Avatar } from '@mui/material';
import ProposalsList from 'components/ProposalsList';

const GET_SPACE = gql`
  query GetSpace($id: String!) {
    space(id: $id) {
      id
      name
      members
      avatar
    }
  }
`;

interface Space {
  id: string;
  name: string;
  members: number;
  avatar: string;
}

interface SpaceData {
  space: Space;
}

const SpaceDetails: React.FC = () => {
  const spaceId = 'sagetowersdao.eth';
  const { loading, error, data } = useQuery<SpaceData>(GET_SPACE, {
    variables: { id: spaceId },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const { id, name, members, avatar } = data?.space || {};

  return (
    <Box>
      <Typography variant="h1">Space Details</Typography>
      <Avatar src={avatar} alt={`${name} logo`} />
      <Typography variant="h2">{name}</Typography>
      <Typography>Members: {members}</Typography>
      <Typography variant="h2">Proposals</Typography>
      <ProposalsList/>
    </Box>
  );
};

export default SpaceDetails;
