import { useState } from "react"
import { usePrepareContractWrite, useContractWrite, useWaitForTransaction } from "wagmi"
import { utils, BigNumber } from "ethers"
import {
  Box,
  Typography,
  Modal,
  Button,
  ButtonGroup
} from "@mui/material"
import {MULTIPLY_GAS_LIMIT } from "config"
import { multiplyBigNumberByFloat, formatEtherFixed } from "utils/numbers"
import VoidriteMintABI from "abi/Voidrite.json"
import TokenView from "components/TokenView"
import MintingButtonEnabled from "components/MintingButtonEnabled"
import useWindowSize from "hooks/useWindowSize"
import MintingButtonDisabled from "./MintingButtonDisabled"
const MINT_CONTRACT_ADDRESS = "0x3ab85c1ED41A9f8275f7a446DaF5D7426e8eC839";

interface Props {
    isConnected: boolean,
    mintOpen: boolean,
    mintPrice: BigNumber,
    maxPerTransaction: number
}

const VoidriteMintButton = ({ 
    isConnected,
    mintOpen,
    mintPrice,
    maxPerTransaction
    }: Props) => {
    const windowSize = useWindowSize()
    const [dialog, setDialog] = useState("")
    const [mintingPreview, setMintingPreview] = useState(false)
    const [amountToMint, setAmountToMint] = useState(1) // Assume we're minting 1 token initially
    const handleMintingPreviewOpen = () => setMintingPreview(true)
    const handleMintingPreviewClose = () => setMintingPreview(false)
    const finalBuyprice = mintPrice ? mintPrice : BigNumber.from(0);
    const { config } = usePrepareContractWrite({
      abi: VoidriteMintABI,
      address: MINT_CONTRACT_ADDRESS,
      functionName: "mint",
      args: [amountToMint],
       overrides: {
        value:finalBuyprice.mul(amountToMint),
      },
    });
    let customRequest = config.request ? {
        data: config.request?.data,
        from: config.request?.from,
        gasLimit: multiplyBigNumberByFloat(config.request?.gasLimit, MULTIPLY_GAS_LIMIT),
        to: config.request?.to,
        value: config.request?.value
      } : undefined
    
      const { data, isError, isLoading, write } = useContractWrite({
        ...config,
        request: customRequest,
        onSuccess(data) {
          setDialog("Transaction pending...")
        }
    })
    
    const waitForTransaction = useWaitForTransaction({
      hash: data?.hash,
      confirmations: 1,
      onSuccess(data) {
        handleMintingPreviewOpen()
        setDialog("")  
        setTimeout(() => {
          handleMintingPreviewClose();
        }, 2500);

      }
    })

     // increase amountToMint by 1, but not more than maxPerTransaction
  const increaseAmountToMint = () => {
    setAmountToMint(prevAmount => prevAmount < maxPerTransaction ? prevAmount + 1 : prevAmount);
  }

  // decrease amountToMint by 1, but not lower than 1
  const decreaseAmountToMint = () => {
    setAmountToMint(prevAmount => prevAmount > 1 ? prevAmount - 1 : 1);
  }

function handleAmountChange(e: React.ChangeEvent<HTMLInputElement>) {
    const inputValue = parseInt(e.target.value);
    if (inputValue >= 1 && inputValue <= maxPerTransaction) {
      setAmountToMint(inputValue);
    }
}

  return (
    <>
      {
        mintOpen && 
        (
          <ButtonGroup variant="text" color="secondary" >
          <Button variant="contained" sx={{ boxShadow: '0px 0px 0px 5px rgba(128, 226, 126, 0.0)' , zIndex:"2", width:"2vw",  mr:"-5px" ,fontSize:"1.5rem" }}   onClick={decreaseAmountToMint}>-</Button>
      
          <Box sx={{backgroundColor:"#232333", m:"0px", zIndex:"3"} }>
          <Box  sx={{ zIndex:"3" , display: 'flex', alignItems: 'center',mt:"0.5vh", ml:"0.5vh",mr:"0.5vh"}}>
     
    <input 
  type="number" 
  value={amountToMint} 
  onChange={handleAmountChange} 
  min="1" 
  style={{
    width: '50px', 
    fontSize: '14px',
    textAlign: 'center', 
    fontWeight: 'bold',
    border: 'none', 
    backgroundColor: 'transparent', 
    color: 'inherit',
    appearance: 'textfield',
    MozAppearance: 'textfield',
    WebkitAppearance: 'textfield'
  }} 
/>

         <Typography fontSize={"0.75rem"} fontWeight={600}>
         for {formatEtherFixed((mintPrice.mul(amountToMint).toString()),4)} {'SGSTN'}
        </Typography>
     
    </Box>
    <MintingButtonEnabled
              message={"Mint Voidrite"}
              contractPurchase={write}
            />
   </Box>
   <Button variant="contained"  sx={{ boxShadow: '0px 0px 0px 5px rgba(128, 226, 126, 0.0)' , zIndex:"2", width:"2vw",  ml:"-5px" ,fontSize:"1.5rem" }}     onClick={increaseAmountToMint}>+</Button>
        </ButtonGroup>
        )
      }
      {
        !mintOpen && 
        (
          <MintingButtonDisabled
            message={`Mint is closed`} 
          />
        )
      }
      {
       !isConnected &&
        (
          <Typography fontWeight={800} fontStyle="italic">
            Connect to mint...
          </Typography>
        )
      }
      <Box marginTop={1}>
        <Typography fontStyle="italic">
          {dialog}
        </Typography>
      </Box>
      <Modal
  open={mintingPreview}
  onClose={handleMintingPreviewClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
<Box
  sx={{
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {xs: '85%', sm: '85%', md:'40%', lg: '40%'},
    bgcolor: "black",
    border: "none",
    boxShadow: 0,
    padding: 5,
  }}
>
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Typography id="modal-modal-title" variant="h1" fontSize="28px"  sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      Voidrite Harvested!
    </Typography>
    <Box marginTop={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
     
        <img src="media/Voidrite.png" alt="Voidrite" style={{width: '100%', height: '100%'}} />
      
    </Box>
  </Box>
</Box>

</Modal>
    </>
  )
}

export default VoidriteMintButton
