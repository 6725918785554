
import Page from "components/Page";
import { Box, Button,Grid, Link, Typography } from "@mui/material";
import {
  useAccount,
  useConnect,
  useDisconnect,
  useNetwork
} from 'wagmi'
//import Web3 from 'web3';
import { BigNumber } from 'ethers'

import Connect from "components/Connect";

import RadiantGiftMintCard from "components/RadiantGiftMintCard";

import InfoCard from "components/InfoCard";

import { EXPECTED_CHAIN_ID } from "config";

import { fetchBalance } from 'wagmi/actions'
import { debounce } from "lodash";
import  { useEffect, useState } from "react";
import RadiantGiftGallery from "components/RadiantGiftGallery";
const RadiantGiftPage = () => {
  const { address ,isConnected } = useAccount();
 // const { connectors, error, isLoading, pendingConnector } = useConnect();
  //const { disconnect } = useDisconnect();
  //const connect = useConnect({
    //onSuccess(data) {
     // console.log('Connect', data)
   // },
 // })
  //const radiantGiftContract = {
   // address: '0xD2fF6D8c9989DFaD80a52393D72A4EDC51Ac1AB2', // Replace with your contract address
   // abi: RadiantGiftABI,
  //}
  
  function generateRandomString(length : number) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const account = useAccount({
    onConnect({ address, connector, isReconnected }) {
      console.log('Connected', { address, connector, isReconnected })
      let tmpaddress = address ? address : '0x3ab85c1ED41A9f8275f7a446DaF5D7426e8eC839';
      const bal = fetchBalance({address: tmpaddress});
      console.log(bal);
    },
    onDisconnect() {
      console.log('Disconnected')
    },
  })
  const { chain, chains } = useNetwork();

  const [MintPrice, setMintPrice] = useState<any | BigNumber>(BigNumber.from(10));

  const [CurrentSupply, setCurrentSupply] = useState<any | Number>(0);
  const [CurrentAddress, setCurrentAddress] = useState<any | string>("0x3ab85c1ED41A9f8275f7a446DaF5D7426e8eC839");
useEffect(() => {
  if (address) { 
    const interval = setInterval(() => {
     if (address) {
       
         fetchLLMBalance(address);
        }
    }, 12000); 
  }
  }, []);
const [UserBalance, setUserBalance] = useState<any | string>("0");
useEffect(() => {
  try{
  if (address) {
    setCurrentAddress(address);
      console.log(chain?.id)
      if(chain?.id ===  EXPECTED_CHAIN_ID) {
        SetIsCorrectNetwork(true);   
       fetchLLMBalance(address);
      } else {
       if(isConnected){
       addCustomNetwork();
       }  
      }
    }
  }
  catch(err) {}
  }, [address, chain?.id, debounce]);
 
  const fetchLLMBalance = async (walletAddress: `0x${string}` ) => {
    const bal = await fetchBalance({address: walletAddress, token:"0x3C856b95ADe474a321c92b5002436b7C57Fe5F96"});  
    setUserBalance(bal.formatted);
    console.log(bal.formatted);  
  };
const [IsCorrectNetwork, SetIsCorrectNetwork] = useState<boolean>(false);

const addCustomNetwork = async () => {
  if (typeof window.ethereum !== 'undefined') {
      try {
          const params = {
            chainId: '0x55d76c9', 
             chainName: 'Sage Towers',
            nativeCurrency: {
              name: 'Sage Stone',
              symbol: 'SGSTN',
              decimals: 18,
            },
            rpcUrls: ['https://chain.sagetowers.com/'],
            blockExplorerUrls: ['https://scan.sagetowers.com/'],
          };
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [params],
          });
        } catch (error) {
          console.error(error);
        }
  } else {
      alert('Please install Wallet');
  }
};

const rng = generateRandomString(10);
const CurrentSupplyString = CurrentSupply? CurrentSupply.toString() : "0";
const numUserBalance = parseFloat(UserBalance);
  return (
    <Page>
      {!address &&(
      <Box
        bgcolor="rgba(23, 23, 33, 0.85)"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 1.5,
          width: "100%",
          padding: "0.5rem",
          borderRadius: "3px",
        }}
      >
         <Typography variant="h1" fontSize={"3.75rem"}>Radiant Gifts</Typography> 
        <>  
        <Typography variant="h3" fontSize={"1.0rem"}>Connect to get your item</Typography>
         <Connect/>
       <Typography variant="h2" fontSize={"1.30rem"} pt={2} pb={1}>AI Generated Metaverse Items</Typography>
        <Typography pt={"3.5vh"} variant="h3" fontSize={"1.0rem"}>Token Standard: ERC721</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Blockchain: Sage Towers Testnet</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Mint Price: 100 LLM</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Max Supply: 10,000</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>AI Generated: 100%</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Metadata: Chatgpt 3.5</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Image Model: SDXL 1.0</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>License: CC0</Typography>
        <Typography variant="body1" fontSize={"1.1rem"}>* These will carry over to Mainnet!</Typography>
        </> 
         </Box>
   )}

        {address &&(
      <Box
        bgcolor="rgba(23, 23, 33, 0.85)"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent:  'space-between',
          textAlign: "center",
          
          width: "100%",
          
          padding: "0.25rem",
          borderRadius: "3px",
         
        }}
      >
  <Grid container mt={"0.1vh"}  style={{width: '100%'}} display="flex" justifyContent="center" alignItems="center" spacing={4}>
  <Grid item sm={12} md={4} mt={"-2.5vh"}   >
  <RadiantGiftMintCard isConnected={isConnected} mintPrice={MintPrice} currentSupply={CurrentSupplyString} userAddy={CurrentAddress} random ={rng} />
  </Grid>
</Grid>  
<div>
  
{numUserBalance >= 10 && (
            <Typography  mt={2} mb={2} variant="h1" fontSize="1.40rem">
                Your LLM: {UserBalance}
            </Typography>)}

            {numUserBalance <= 10 && (
              <>
           <Typography variant="h1" fontSize="1.20rem" mt={"3vh"}  mb={1}>
                    <Link fontSize="1.20rem" href="/llm" mt={"0.1vh"}  mb={1}>   Your LLM balance is low. Get some here. </Link></Typography>
             </>
            )}
        </div>
        <Typography mt={0.4} mb={0.4}  variant="h3" fontSize={"1.1rem"}> <strong>Token Standard:</strong>   ERC721</Typography>
        <Typography mt={0.4} mb={0.4}  variant="h3" fontSize={"1.1rem"}> <strong>Blockchain:</strong>  Sage Towers Testnet*</Typography>
        <Typography mt={0.4} mb={0.4}  variant="h3" fontSize={"1.1rem"}> <strong>Mint Price:</strong>  100 LLM</Typography>
        <Typography mt={0.4} mb={0.4}   variant="h3" fontSize={"1.1rem"}> <strong>Soft Supply:</strong>  1,000</Typography>
        <Typography mt={0.4} mb={0.4}  variant="h3" fontSize={"1.1rem"}> <strong>AI Generated:</strong>  100%</Typography>
        <Typography  mt={0.4} mb={0.4} variant="h3" fontSize={"1.1rem"}> <strong>Metadata:</strong>  Chatgpt 3.5</Typography>
        <Typography mt={0.4} mb={0.4}  variant="h3" fontSize={"1.1rem"}> <strong>Image Model:</strong>  SDXL 1.0</Typography>
        <Typography mt={0.4} mb={0.4}  variant="h3" fontSize={"1.1rem"}> <strong>License:</strong>  CC0</Typography>
        <Typography mt={2} mb={3}variant="body1" fontSize={"1.2rem"}><strong>* These will carry over to Mainnet!</strong></Typography>


      </Box>)}
      <Box
       sx={{
        mt: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 1.5,
          width: "100%",
          height: "100%",
          padding: "0.5rem",
          borderRadius: "3px",
        }}
      >
        <Box
       sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 1.5,
          width: "100%",
          height: "100%",
          //padding: "0.5rem",
          borderRadius: "3px",
          //bgcolor: "rgba(22,22,32,0.5)",
      boxShadow: "0px 0px 15px rgba(128, 226, 126, 0.9)", 
      backgroundColor: "rgba(23, 23, 33, 1)",
      filter: 'drop-shadow(0px 0px 15px rgba(128, 226, 126, 0.9))',
padding: 2,
        }}
      >

<Typography variant="h1" pt={0.25} pb={1}>Previously Generated:</Typography>
<Box sx={{flexDirection: "column",
        justifyContent: "center",
        alignItems: "center", maxWidth:"75vw"}}>
        <RadiantGiftGallery/></Box>
        
      <InfoCard
     title="What are Radiant Gifts?"
     text="Radiant Gifts are a revolutionary form of ERC721 NFTs exclusive to the Sage Towers metaverse. These NFTs are minted using advanced AI technologies from OpenAI and Stability AI. They hold immense utility within our gaming and social interaction scenarios, making them an intriguing surprise to unravel. Their attributes, appearance, and value are all dynamically generated, ensuring a unique asset that amplifies the Sage Towers experience."
     imageUrl="media/basic14.png"
   />

<InfoCard
     title="Why are Radiant Gifts special?"
     text="The power of Radiant Gifts lies in their creation. Once minted using Logical Mechanism tokens, they employ ChatGPT 3.5 and SDXL 1.0 to craft a unique identity. This complex process involves generating intricate item details and visuals. As each Radiant Gift is a mystery, their reveal becomes a thrilling event that adds another layer of immersion to the Sage Towers metaverse."
     imageUrl="media/basic6.png"
   />

<InfoCard
     title="What can I do with Radiant Gifts?"
     text="Radiant Gifts have extensive use across the Sage Towers metaverse. They hold utility in our survival game, providing an unknown edge to your gameplay. Within social interactions, they add an element of surprise and can serve as unique talking points with our 'Living' NPCs. The versatility of Radiant Gifts adds a whole new dimension of engagement within the Sage Towers metaverse."
     imageUrl="media/basic11.png"
   />

<InfoCard
     title="What's the process behind a Radiant Gift?"
     text="The creation of each Radiant Gift is a technological marvel. We begin by prompting ChatGPT 3.5 with a special query to generate the item's specifics, even its image prompt. The image prompt is then processed using SDXL 1.0 from Stability AI for an exclusive visual representation. Finally, the item's name is overlaid, culminating in the minting of a unique, AI-generated Radiant Gift."
     imageUrl="media/basic9.png"
   />
          
       </Box>
       </Box>
    </Page>
  );
};

export default RadiantGiftPage;


