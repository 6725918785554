import {
  Button,
  Typography
} from "@mui/material"

interface Props {
  message: string,
  contractPurchase: any
}

const MintingButtonEnabled = ({message, contractPurchase}: Props) => {  
  return (
    <Button
    variant="text"
    color="primary"
    disabled={!contractPurchase} 
    onClick={() => contractPurchase?.()}
    sx={{ 
      minWidth: "200px",
      paddingTop: 0.75,
      paddingRight: 0.45,
      paddingLeft: 0.45,
      paddingBottom: 0.75,
      boxShadow: "none",
      textTransform: "none",
    }}>
      { contractPurchase && (
      <Typography fontFamily={'Cinzel'} fontSize={16} fontWeight={600} color={"#80e27e"}>
        {message}
      </Typography>)}  
      { !contractPurchase && (
      <Typography fontFamily={'Cinzel'} fontSize={16} fontWeight={600} color={"#80e27e"}>
        {message}
      </Typography>)}
    </Button>
  )
}

export default MintingButtonEnabled