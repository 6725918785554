import React from 'react';
import { useQuery, gql } from '@apollo/client';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Link,
} from '@mui/material';

const GET_VOTES = gql`
  query Votes($first: Int!, $skip: Int!, $proposalId: String!, $orderBy: String!, $orderDirection: OrderDirection!) {
    votes(
      first: $first
      skip: $skip
      where: { proposal: $proposalId }
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      id
      voter
      vp
      vp_by_strategy
      vp_state
      created
      proposal {
        id
      }
      choice
      space {
        id
      }
    }
  }
`;


const GET_PROPOSALS = gql`
  query Proposals($first: Int!, $skip: Int!, $state: String!, $orderBy: String!, $orderDirection: OrderDirection!) {
    proposals(
      first: $first
      skip: $skip
      where: { space_in: ["yam.eth"], state: $state }
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      id
      title
      body
      choices
      start
      end
      snapshot
      state
      author
    }
  }
`;


interface Vote {
    id: string;
    voter: string;
    vp: string;
    vp_by_strategy: string;
    vp_state: string;
    created: number;
    proposal: {
      id: string;
    };
    choice: number;
    space: {
      id: string;
    };
  }
  
  interface VotesData {
    votes: Vote[];
  }
  interface VotesListProps {
    proposalId: string;
  }
  
  const VotesList: React.FC<VotesListProps> = ({ proposalId }) => {
    const { loading, error, data } = useQuery<VotesData>(GET_VOTES, {
      variables: { first: 1000, skip: 0, proposalId, orderBy: "created", orderDirection: "desc" },
    });
  
    if (loading) return <p>Loading votes...</p>;
    if (error) return <p>Error loading votes: {error.message}</p>;
  
    const votes = data?.votes;
  
    if (!votes || votes.length === 0) {
      return <Typography>No votes found for this proposal.</Typography>;
    }
  
    return (
      <List>
        {votes.map((vote: Vote) => (
          <ListItem key={vote.id}>
            <ListItemText>
              <Typography>Voter: {vote.voter}</Typography>
              <Typography>Choice: {vote.choice}</Typography>
              <Typography>VP: {vote.vp}</Typography>
              <Typography>VP by Strategy: {vote.vp_by_strategy}</Typography>
              <Typography>VP State: {vote.vp_state}</Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    );
  };
  
  
  interface Proposal {
    id: string;
    title: string;
    body: string;
    choices: string[];
    start: number;
    end: number;
    snapshot: string;
    state: string;
    author: string;
  }
  

interface ProposalsData {
  proposals: Proposal[];
}



const ProposalsList: React.FC = () => {
    const { loading, error, data } = useQuery<ProposalsData>(GET_PROPOSALS, {
      variables: { first: 10, skip: 0, state: "closed", orderBy: "start", orderDirection: "desc" },
    });
  
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
  
    const proposals = data?.proposals;
  
    if (!proposals || proposals.length === 0) {
      return (
        <Box>
          <Typography>No proposals found.</Typography>
          <Link href="https://snapshot.org/#/sagetowersdao.eth" target="_blank" rel="noopener">
            Visit Sage Tower Dao @ Snapshot.org to create a proposal.
          </Link>
        </Box>
      );
    }
  
    return (
        <List>
        {proposals.map((proposal: Proposal) => (
          <ListItem key={proposal.id}>
            <Box>
              <Typography variant="h4">{proposal.title}</Typography>
              <ListItemText>{proposal.body}</ListItemText>
              <Typography variant="h6">Votes:</Typography>
              <VotesList proposalId={proposal.id} />
            </Box>
          </ListItem>
        ))}
      </List>
    );
  };
  

export default ProposalsList;
