import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import LandingPage from "pages/LandingPage"
import ProjectsPage from "pages/ProjectsPage"
import ProjectPage from "pages/ProjectPage"
import TokenPage from "pages/TokenPage"
import Providers from "components/Providers"
import LoadingScreen from "components/LoadingScreen"
import SnapshotPage from "pages/SnapshotPage"
import ConnectPage from "pages/ConnectPage"
import VoidritePage from "pages/VoidritePage"
import TermsPage from "pages/TermsPage"
import PrivacyPage from "pages/PrivacyPage"
import StreamingPage from "pages/StreamingPage"
import GuidelinesPage from "pages/GuidelinesPage"
import LicensePage from "pages/LicensePage"
import WebglPage from "pages/WebglPage"
import RadiantGiftPage from "pages/RadiantGiftPage"
import GamePage from "pages/GamePage"
import LLMPage from "pages/LLMPage"


function App() {
  return (
    <Providers>
      <Router>
      <LoadingScreen />
        <Routes>
          <Route index element={<LandingPage/>}/>
          <Route path="projects" element={<ProjectsPage/>}/>
          <Route path="project/:projectId" element={<ProjectPage/>}/>
          <Route path="token/:id" element={<TokenPage/>}/>
          <Route path="snapshot" element={<SnapshotPage/>}/>
          <Route path="profile" element={<ConnectPage/>}/>
          <Route path="voidrite" element={<VoidritePage/>}/>
          <Route path="terms" element={<TermsPage/>}/>
          <Route path="privacy" element={<PrivacyPage/>}/>
          <Route path="streaming" element={<StreamingPage/>}/>
          <Route path="guidelines" element={<GuidelinesPage/>}/>
          <Route path="license" element={<LicensePage/>}/>
          <Route path="game" element={<GamePage/>}/>
          <Route path="radiantgifts" element={<RadiantGiftPage/>}/>
          <Route path="radiantgift" element={<RadiantGiftPage/>}/>
          <Route path="llm" element={<LLMPage/>}/>
          <Route path="bridge" element={<LLMPage/>}/>
        </Routes>
      </Router>
      <ToastContainer
        autoClose={10000}
        position="bottom-right"
        theme="dark"
        newestOnTop
        pauseOnHover
        pauseOnFocusLoss
      />
    </Providers>
  )
}

export default App
