import { useState } from "react"
import { 
  Box,
  Link,
  AppBar,
  Toolbar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import Connect from "components/Connect"
import { Typography } from "@mui/material"
import { Type } from "react-toastify/dist/utils"
import NetworkButton from "./NetworkButton"

const items = [
  {
    label: "Privacy Policy",
    url: "/privacy",
    enabled: true,
    target: "_self"
  },
  {
    label: "Terms and Conditions",
    url: "/terms",
    enabled: true,
    target: "_self"
  },
  {
    label: "Streaming Permissions",
    url: "/streaming",
    enabled: true,
    target: "_self"
  },
  {
    label: "Community Guidelines",
    url: "/guidelines",
    enabled: true,
    target: "_self"
  },
  {
    label: "CC0 License",
    url: "/license",
    enabled: true,
    target: "_self"
  },


]

const Footer = () => {
  const addCustomNetwork = async (event: { preventDefault: () => void }) => {
    event.preventDefault(); // Prevent the link from redirecting

    if (typeof window.ethereum !== 'undefined') {
      try {
        // Define network parameters
        const params = {
          chainId: '0x55d76c9', 
           chainName: 'Sage Towers',
          nativeCurrency: {
            name: 'Sage Stone',
            symbol: 'SGSTN',
            decimals: 18,
          },
          rpcUrls: ['https://chain.sagetowers.com/'],
          blockExplorerUrls: ['https://scan.sagetowers.com/'],
        };

        // Add Ethereum network
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [params],
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      alert('Please install Wallet');
    }
  };
  
  return (
    <Box sx={{width: "100%", display: "flex", justifyContent: "center", backgroundColor: "rgba(128, 226, 126, 0.075)", boxShadow: "0px 0px  25px rgba(128, 226, 126, 0.17 )"}}>
      <AppBar component="nav"  position="sticky" elevation={1} sx={{backgroundColor: "rgba(128, 226, 126, 0.0)", boxShadow: 0, zIndex: 100}}>
        <Toolbar sx={{width: "100%", display: "flex", margin: "auto", justifyContent: "space-between", backgroundColor: "rgba(128, 226, 126, 0.0)"}}>
          <Box sx={{display: "flex", backgroundColor: "rgba(128, 226, 126, 0.0)", justifyContent: "center", alignItems: "center"}}>
            
            <Box sx={{paddingRight: "35px", width:'9vw', display: {xs: "none", sm: "none", md: "flex", lg:"flex" }}}>
              <Link href="/" sx={{display: "flex", width:'9vw'}}>
              <img src="media/logo.png" alt="Sage Towers" width={'100%'}  />
              </Link>
            </Box>
            <Box sx={{paddingRight: "2vw", width:'25vw', display: {xs: "flex", sm: "flex", md: "none", lg:"none" }}}>
              <Link href="/" sx={{display: "flex", width:'25vw'}}>
              <img src="media/logo.png" alt="Sage Towers" width={'100%'}  />
              </Link>
            </Box>
            <Box sx={{display: {xs: "none", sm: "block"}, marginTop: "5px"}}>
              {items.map((item) => (
                  <Link 
                  key={item.label} 
                  href={item.url} 
                  target={item.target}
                  underline="hover"
                  sx={{fontSize: 14, fontWeight: 600, color: item.enabled ? "primary" : "lightgrey", paddingRight: "25px", pointerEvents: item.enabled ? "auto" : "none"}}>
                  {item.label}
                </Link>
              ))}
            </Box>
            <Box sx={{display: {xs: "block", sm: "none"}, marginLeft:  "10px", marginBottom:"4px"}}>
              {items.map((item) => (
                  <Link 
                  key={item.label} 
                  href={item.url} 
                  target={item.target}
                  underline="hover"
                  sx={{fontSize: 10, fontWeight: 300, color: item.enabled ? "primary" : "lightgrey", paddingRight: "10px", pointerEvents: item.enabled ? "auto" : "none"}}>
                  {item.label}
                </Link>
              ))}
            </Box>
          </Box>
          <Box sx={{ display: {xs: "none", sm: "none", md: "flex", lg:"flex" },  gap: 1  ,  flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",}}>
            <Box>
     
        <Link onClick={addCustomNetwork}  target="_blank" sx={{ m:1}} >
        <img  src="media/icons/sagechain.svg" alt="Discord" height='28px' width='28px' />
        </Link>
          <Link href="https://discord.gg/sagetowers" target="_blank" sx={{ m:1}} >
        <img  src="media/icons/discord.svg" alt="Discord" height='28px' width='28px' />
        </Link>
        
          <Link href="https://twitter.com/sagetowersdao" target="_blank" sx={{ m:1}} >
        <img src="media/icons/twitter.svg" alt="Twitter" height='27px' width='27px' />
        </Link>
        <Link href="https://amazastrophic.com" target="_blank" sx={{ m:1}} >
        <img src="media/icons/amzcorp.png" alt="Twitter" height='27px' width='27px' />
        </Link>
        
        </Box>
          <Box>
            
       
          </Box>
          </Box>
         
        </Toolbar> <Typography variant="body1" fontSize='11px' align="center">© 2023 Sage Towers DAO. All AI-generated content is licensed under CC0 and is in the public domain.</Typography>
        <Typography variant="body1" fontSize='11px' align="center">  Sage Towers is currently an Arbitrum Orbit chain and currently settles on Arbitrum Goerli. When testing is done and it's avalable, we plan to move over to launch/settle Sage Towers Orbit chain on Arbitrum Nova. A certain amount of assets, such as LLM, will be bridged/airdropped over to the final layer.</Typography>
      </AppBar>
     
    </Box>
  )
}

export default Footer