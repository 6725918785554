import { useParams } from "react-router-dom";
import Page from "components/Page";
import { Box, Button, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import {
  useBalance,
  useAccount,
  useConnect,
  useDisconnect,
  useSignMessage,
  useContractReads,
  useNetwork
} from 'wagmi'

import VoidriteABI from 'abi/Voidrite.json' // Make sure you import the ABI of your contract here

import { BigNumber, ethers } from 'ethers'
import { ConnectButton } from "@rainbow-me/rainbowkit";
import Connect from "components/Connect";
import { Avatar } from '@mui/material';
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import VoidriteMintButton from "components/VoidriteMintButton";
import VoidriteMintingInfo from "components/VoidriteMintingInfo";
import VoidriteSellButton from "components/VoidriteSellButton";
import VoidriteBuyButton from "components/VoidriteBuyButton";
import VoidriteMintCard from "components/VoidriteMintCard";
import VoidriteSellCard from "components/VoidriteSellCard";
import VoidriteBuyCard from "components/VoidriteBuyCard";
import VoidriteInfo from "components/VoidriteInfo";
import InfoCard from "components/InfoCard";
import { CustomConnectButton } from "components/CustomConnectButton";
import theme from "theme";
import { text } from "express";
import { title } from "process";
import Footer from "components/Footer";
import { EXPECTED_CHAIN_ID } from "config";
const VoidritePage = () => {
  const { address , connector, isConnected } = useAccount();
  const { connect, connectors, error, isLoading, pendingConnector } = useConnect();
  const { disconnect } = useDisconnect();

  const voidriteContract = {
    address: '0x3ab85c1ED41A9f8275f7a446DaF5D7426e8eC839', // Replace with your contract address
    abi: VoidriteABI,
  }
 
  const { chain, chains } = useNetwork();
  const lmaoo = useBalance({
    address: address ? address : '0x3ab85c1ED41A9f8275f7a446DaF5D7426e8eC839',
  })
  const [MintingEnabled, setMintingEnabled] = useState<any | null>(null);
  const [MintPrice, setMintPrice] = useState<any | BigNumber>(BigNumber.from(0));
  const [SellPrice, setSellPrice] = useState<any |  BigNumber>(BigNumber.from(0));
  const [BuyPrice, setBuyPrice] = useState<any | BigNumber>(BigNumber.from(0));
  const [MaxSupply, setMaxSupply] = useState<any | Number>(500000);
  const [CurrentSupply, setCurrentSupply] = useState<any | Number>(0);
  const [MaxMintsPerTx, setMaxMintsPerTx] = useState<any | Number>(10);
  const [VoidritePool, setVoidritePool] = useState<any | Number>(0);
  const [EthPoolBalance, setEthPoolBalance] = useState<any |  BigNumber>(BigNumber.from(0));
  const [UserBalance, setUserBalance] = useState<any | BigNumber>(BigNumber.from(0));
  const { data, isError, refetch } = useContractReads({
  contracts: [
    {
      ...voidriteContract,
      functionName: 'MintingEnabled',
    },
    {
      ...voidriteContract,
      functionName: 'MintPrice',
    },
    {
      ...voidriteContract,
      functionName: 'SellPrice',
    },
    {
      ...voidriteContract,
      functionName: 'BuyPrice',
    },
    {
      ...voidriteContract,
      functionName: 'MaxSupply',
    },
    {
      ...voidriteContract,
      functionName: 'CurrentSupply',
    },
    {
      ...voidriteContract,
      functionName: 'MaxMintsPerTx',
    },
    {
      ...voidriteContract,
      functionName: 'VoidritePool',
    },
    {
      ...voidriteContract,
      functionName: 'EthPoolBalance',
    },
    {
      ...voidriteContract,
      functionName: 'balanceOf',
      args: [address, BigNumber.from(1)],
    }
  ],
  watch:true,
  onSuccess(data) {
      setMintingEnabled(data[0]);
      setMintPrice((data[1] as BigNumber));
      setSellPrice((data[2] as BigNumber));
      setBuyPrice((data[3] as BigNumber));
      setMaxSupply((data[4] as BigNumber));
      setCurrentSupply((data[5] as BigNumber));
      setMaxMintsPerTx((data[6] as BigNumber));
      setVoidritePool((data[7] as BigNumber));
      setEthPoolBalance((data[8] as BigNumber));
      setUserBalance((data[9] as BigNumber));
    },
})

useEffect(() => {
  try{
  if (address) {
      console.log(chain?.id)
      if(chain?.id ===  EXPECTED_CHAIN_ID) {
        SetIsCorrectNetwork(true);
      } else {
      // switchNetwork({
       // chainId: EXPECTED_CHAIN_ID,
       if(isConnected){
   addCustomNetwork();
       }
      //})  
      }
    }
  }
  catch(err) {}
  }, [address, chain?.id]);
const [IsCorrectNetwork, SetIsCorrectNetwork] = useState<boolean>(false);

useEffect(() => {
if (address) { // Only run if an address is set
  const interval = setInterval(() => {
    refetch();
  }, 6000); // Every 5 seconds

  return () => clearInterval(interval); // Clear interval on component unmount
}
}, [address, refetch]);

const addCustomNetwork = async () => {
  if (typeof window.ethereum !== 'undefined') {
      try {
          // Define network parameters
          const params = {//0xa4ba55D76C9
            chainId: '0x55d76c9', 
             chainName: 'Sage Towers',
            nativeCurrency: {
              name: 'Sage Stone',
              symbol: 'SGSTN',
              decimals: 18,
            },
            rpcUrls: ['https://chain.sagetowers.com/'],
            blockExplorerUrls: ['https://scan.sagetowers.com/'],
          };
  
          // Add Ethereum network
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [params],
          });
        } catch (error) {
          console.error(error);
        }
  } else {
      alert('Please install Wallet');
  }
};

const UserBalanceString = UserBalance? UserBalance.toNumber() : 0;
const ethBal ="0.00";// lmaoo.data? lmaoo.data?.formatted?.toString().substring(0,8) : "0.00";
//const VoidritePoolString = VoidritePool? VoidritePool.toString() : "0";
//const EthPoolBalanceString = EthPoolBalance? EthPoolBalance.toString() : "0";
//const MintPriceString = MintPrice? MintPrice.toString() : "0";
//const SellPriceString = SellPrice? SellPrice.toString() : "0";
//const BuyPriceString = BuyPrice? BuyPrice.toString() : "0";
const MaxSupplyString = MaxSupply? MaxSupply.toString() : "0";
const CurrentSupplyString = CurrentSupply? CurrentSupply.toString() : "0";
const MaxMintsPerTxString = MaxMintsPerTx? MaxMintsPerTx.toString() : "0";

  return (
    <Page>
      {!address &&(
      <Box
        bgcolor="rgba(23, 23, 33, 0.85)"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 1.5,
          width: "100%",
          padding: "0.5rem",
          borderRadius: "3px",
        }}
      >
         <Typography variant="h1" fontSize={"3.75rem"}>Voidrite</Typography>
        <>   <Connect/>
      
        <Typography pt={"3.5vh"} variant="h3" fontSize={"1.0rem"}>Token Standard: ERC1155</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Blockchain: Sage Towers L3</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Mint Price: 0.001337 SGSTN</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Max Supply: 500,000 Voidrite</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Max per Transaction: 4999 Voidrite</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Sell Price: 0.00128 SGSTN</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Buy Price: 0.00139 SGSTN</Typography>

        </> 
         </Box>
   )}

        {address &&(
      <Box
        bgcolor="rgba(23, 23, 33, 0.85)"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent:  'space-between',
          textAlign: "center",
          
          width: "100%",
          
          padding: "0.25rem",
          borderRadius: "3px",
         
        }}
      >
  <Typography variant="h1" fontSize={"2.70rem"} pt={-1} pb={1}>VOIDRITE</Typography>
  {UserBalanceString > 0 && (
 <Typography variant="h5" fontSize={"1.10rem"} pt={0} pb={1}>Your Balance: {UserBalanceString}</Typography>


  )}
  <Grid container  style={{width: '100%'}} display="flex" justifyContent="center" alignItems="center" spacing={4}>
  <Grid item sm={12} md={4} >
<VoidriteBuyCard isConnected={isConnected} voidritePoolBalance={VoidritePool} buyPrice={BuyPrice} maxBuy={VoidritePool} balance={ethBal}  />
</Grid>
  <Grid item sm={12} md={4}  >
  <VoidriteMintCard isConnected={isConnected} mintOpen={MintingEnabled} mintPrice={MintPrice} maxPerTransaction={MaxMintsPerTxString} currentSupply={CurrentSupplyString} maxSupply={MaxSupplyString} />
  </Grid>
  <Grid item sm={12} md={4} >
  <VoidriteSellCard  isConnected={isConnected} ethPoolBalance={EthPoolBalance} sellPrice={SellPrice} maxSell={UserBalance}  />
</Grid>

</Grid>


      </Box>)}
      <Box
        bgcolor="rgba(23, 23, 33, 0.85)"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 1.5,
          width: "100%",
          padding: "0.5rem",
          borderRadius: "3px",
        }}
      >
        <InfoCard
        title="What is Voidrite?"
        text="Voidrite is an ERC1155 NFT that holds immense value and utility within the Sage Towers metaverse. It serves as a base tier material, granting its holders several pathways to influence the development and direction of the metaverse."
        imageUrl="media/SageCity3.png"
      />
   
            <InfoCard
        title="Why do I need Voidrite?"
        text="Voidrite serves as a crucial component in obtaining exclusive access to the Sage Towers metaverse. To mint a Sage Pass, which grants the highest level of access and privileges, 512 Voidrite are required. For a Hunter License, which unlocks survival gameplay opportunities, 32 Voidrite are needed. Any remaining Voidrite can be converted into Sage Stone at a fair rate, providing players with valuable in-game credits to enhance their experience within the Sage Towers metaverse."
        imageUrl="media/DeepCave2.png"
      />
              <InfoCard
        title="How are the funds being used?"
        text="The funds raised from the sale of Voidrite will be strategically allocated to ensure the growth and sustainability of the Sage Towers metaverse. Half of the funds will be dedicated to setting up liquidity pools for Sage Stone, our primary in-game currency. This will help establish a robust and stable market for Sage Stone, allowing players to seamlessly trade and exchange it within the metaverse, thereby enhancing the overall gaming experience."
        imageUrl="media/DarkZone.png"
      />
            <InfoCard
        title="When are the airdrops and claims?"
        text="Voidrite holders are eligible for occasional airdrops and token claims, providing them with additional rewards and incentives for being part of the Sage Towers community. These airdrops and claims may include exclusive in-game items, currency, or other valuable assets that enhance the overall metaverse experience. We will be announcing these airdrops and claims on our social media channels, so be sure to follow us to stay up to date!"
        imageUrl="media/VoidForest2.png"
      />
  
          <Button size="large"
          variant="outlined"
          color="inherit"
          href="https://docs.sagetowers.com/sage-towers/nfts/voidrite" target="_blank" sx={{ mt:"-5vh" }}>
          Read more about Voidrite here
        </Button>
       </Box>
       
    </Page>
  );
};

export default VoidritePage;
