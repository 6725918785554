import { Card, CardContent, CardHeader, Avatar, Typography, useTheme, styled, Box } from "@mui/material";
import VoidriteMintButton from "components/VoidriteMintButton";
import { BigNumber, ethers } from "ethers";
import { LinearProgress ,linearProgressClasses } from "@mui/material";
import VoidriteSellButton from "./VoidriteSellButton";

interface Props {
    isConnected: boolean,
    ethPoolBalance: BigNumber,
    sellPrice: BigNumber,
    maxSell: BigNumber
}
const StyledCard = styled(Card)(({ theme }) => ({
  transition: "transform 0.3s ease-in-out",
  '&:hover': {
    transform: "scale(1.05)",
    boxShadow: "0px 0px 15px  rgba(65, 179, 163, 1)",
    backgroundColor: "rgba(0, 0, 0, 1)",
    filter: 'drop-shadow(0px 0px 15px rgba(65, 179, 163, 1))',
    '& img': {
      width: '230px',
      objectFit: 'contain',
      filter: 'drop-shadow(0px 0px 10px rgba(128, 226, 126, 0.9))',
      transition: 'filter 0.15s ease-in-out',}
  },
  height: 480,
  minHeight: 480,
  minWidth: 260,
  backgroundColor: "rgba(0, 0, 0, 0.05)",
  color: theme.palette.secondary.contrastText, 
  boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.4)",
  '& img': {
    width: '230px',
    objectFit: 'contain',
    filter: 'drop-shadow(0px 0px 10px rgba(128, 226, 126, 0.3))',
    transition: 'filter 0.3s ease-in-out',

  }
}));

const VoidriteSellCard = ({ isConnected, ethPoolBalance, sellPrice, maxSell}: Props) => {
  const formattedPrice = sellPrice ? ethers.utils.formatUnits(sellPrice).toString().substring(0,7) : null;
  const currentSupply = ethPoolBalance ? ethers.utils.formatUnits(ethPoolBalance) : null;
  const maxSupply = maxSell ? maxSell.toString() : null;
 // const mintingStatus = mintOpen ? "Enabled" : "Disabled";
  const theme = useTheme();



  return (
    <StyledCard>
        <CardHeader
      sx={{ backgroundColor: "rgba(0, 0, 0, 0.0)", color: theme.palette.primary.main }}  
        subheader={   <Typography  style={{fontSize:"1.0rem"}}  p={0.2} variant="h4" component="div">
        Price: {formattedPrice}
      </Typography>}  
        title={<Typography  style={{fontSize:"2.45rem"}}  variant="h1">Sell</Typography>}
      />
          <img src="media/Sell.png" alt="Voidrite" style={{ width: '230px', objectFit: 'contain' }}/>
        
      <CardContent sx={{ m:"-1vh"}} >  
           <Typography p={0.1} variant="body1" component="p" style={{fontSize:"0.75rem"}}>
          Price is 4% less then mint price
        </Typography>
        <Typography p={0.3} variant="body1" component="p" style={{fontSize:"0.75rem"}}>
        SGSTN Pool: {currentSupply}
        </Typography>

     
        <Box mt={"1vh"}>
        <VoidriteSellButton isConnected={isConnected} ethPoolBalance={ethPoolBalance} sellPrice={sellPrice} maxSell={maxSell}  />
       </Box>
      </CardContent>
      </StyledCard>
  );
};

export default VoidriteSellCard;

