import CssBaseline from "@mui/material/CssBaseline"
import "@rainbow-me/rainbowkit/styles.css"
import { ThemeProvider } from "@mui/material/styles"
//import { Chain, getDefaultWallets } from '@rainbow-me/rainbowkit'
import theme from "theme"
import { Chain,RainbowKitProvider, getDefaultWallets, midnightTheme, Theme  } from "@rainbow-me/rainbowkit"
import {   configureChains, createClient, WagmiConfig } from "wagmi"
import { infuraProvider } from "wagmi/providers/infura"
import { alchemyProvider} from "wagmi/providers/alchemy"
import { publicProvider } from "wagmi/providers/public"
import { mainnet } from 'wagmi/chains'
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client"
import { GRAPHQL_URL, INFURA_KEY, EXPECTED_CHAIN_ID } from "config"
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
//import { SageTowers, arbitrumNova} from '@wagmi/chains'
const myCustomTheme: Theme = {
  blurs: {
    modalOverlay: '...',
  },
  colors: {
    accentColor: '#80e27e',
    accentColorForeground: '#232333',
    actionButtonBorder: '#009688 ',
    actionButtonBorderMobile: '#009688 ',
    actionButtonSecondaryBackground: '#232333',
    closeButton: '#F44336',
    closeButtonBackground: '#232333',
    connectButtonBackground: 'rgba(0,0,0,0)',
    connectButtonBackgroundError: '#F44336',
    connectButtonInnerBackground: '#232333',
    connectButtonText: '#009688',
    connectButtonTextError: '#009688',
    connectionIndicator: '#41b3a3',
    downloadBottomCardBackground: '#232333',
    downloadTopCardBackground: '#80e27e',
    error: '#F44336',
    generalBorder: 'rgba(0,0,0,0)',
    generalBorderDim: '#009688',
    menuItemBackground: '#232333',
    modalBackdrop: '...',
    modalBackground: '#232333',
    modalBorder: '#41b3a3',
    modalText: '#009688  ',
    modalTextDim: '#41b3a3',
    modalTextSecondary: '#41b3a3',
    profileAction: '#80e27e',
    profileActionHover: '#F44336',
    profileForeground: '#232333',
    selectedOptionBorder: '#41b3a3',
    standby: '#41b3a3',
  },
  fonts: {
    body: 'Cinzel',
  },
  radii: {
    actionButton: '...',
    connectButton: '...',
    menuButton: '...',
    modal: '...',
    modalMobile: '...',
  },
  shadows: {
    connectButton: '...',
    dialog: '...',
    profileDetailsAction: '...',
    selectedOption: '...',
    selectedWallet: '...',
    walletLogo: '...',
  },
};

const client = new ApolloClient({
  uri: GRAPHQL_URL,
  cache: new InMemoryCache()
})


const snapshotClient = new ApolloClient({
   uri: 'https://hub.snapshot.org/graphql',
  cache: new InMemoryCache()
});
/*
var SageTowers={
   id: 90011337,
   name: "Sage Towers",
   network: "sage-towers",
   nativeCurrency: {
       name: "Sage Stone",
       symbol: "SAGE",
       decimals: 18,
  },
   rpcUrls: {
       default: {
           http: readonly ["https://chain.sagetowers.com"]
      },
       public: {
           http: readonly ["https://chain.sagetowers.com"]
      }
  },
   blockExplorers: {
       etherscan: {
           name: "Scan Sage Towers",
           url: "https://scan.sagetowers.com"
      },
       blockScout: {
           name: "Scan Sage Towers",
           url: "https://scan.sagetowers.com"
      },
       default: {
           name: "Scan Sage Towers",
           url: "https://scan.sagetowers.com"
      }
  },
   contracts: {
       multicall3: {
           address: "0xde43C58A1d4beB6866c75323aADeCA1c69a65e01",
           blockCreated: 12
      }
  }
};
*/
const SageTowersChain: Chain = {
  id: 90011337,
  name: "Sage",
  network: "sage",
  iconUrl: 'https://storage.fleek-internal.com/d10cc1a7-7c9f-4ee6-8a94-e4a13807533d-bucket/Icons/sage.svg',
  //iconBackground: 'rgba(0,0,0,0)',
  nativeCurrency: {
      name: "Sage Stone",
      symbol: "SAGE",
      decimals: 18,
 },
 //arbitrumNova.name,
    rpcUrls: {
     // alchemy: 'https://eth-rinkeby.alchemyapi.io/v2',
      default: "https://chain.sagetowers.com",
      //infura: 'https://rinkeby.infura.io/v3',
      public: 'https://chain.sagetowers.com',
     },
  
  blockExplorers: {
    default: { name: 'Scan Sage', url: 'https://scan.sagetowers.com' },
    etherscan: { name: 'Scan Sage', url: 'https://scan.sagetowers.com' },
  },
  
  testnet: false,
};

// Defaults to goerli testing network if mainnet is not set

const expectedChains = EXPECTED_CHAIN_ID === SageTowersChain.id ? [SageTowersChain,mainnet] : [SageTowersChain,mainnet]
const initialChain = EXPECTED_CHAIN_ID === SageTowersChain.id ? SageTowersChain : SageTowersChain

const { chains, provider, webSocketProvider } = configureChains(
  expectedChains,
  [
    
   
    publicProvider({priority: 2}),
    jsonRpcProvider({priority: 0,
      rpc: chain => ({ http: "https://chain.sagetowers.com",
   
    }), 
    
    }),
    jsonRpcProvider({priority: 1,
      rpc: mainnet => ({ http: "https://mainnet.infura.io/v3/a8c64b6d8fa444d8b682507c3c4d1a25",
   
    }), 
    
    }),
    jsonRpcProvider({priority: 3,
      rpc: chain => ({ http: "https://chain.sagetowers.com",
   
    }), 
    
    }),
  ]
)

/*
const { chains, provider, webSocketProvider } = configureChains(
  expectedChains,
  [
    infuraProvider({apiKey: INFURA_KEY, priority: 1}),
    alchemyProvider({apiKey: "dz6pi9sFkC5FMsjfy7wAzZV0n3bkUYbI", priority: 0}),
    publicProvider({priority: 2})
  ]
)
*/
const { connectors } = getDefaultWallets({
  appName: "Sage Towers", 
  chains
})

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
  webSocketProvider
})

interface Props {
  children: React.ReactNode
}

const AppProvider = ({children}:Props) => {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
         coolMode
         chains={chains}
         initialChain={initialChain}
         theme={
          myCustomTheme
        }>
        <ApolloProvider client={client}>
        <ApolloProvider client={snapshotClient}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
          </ThemeProvider>
          </ApolloProvider>
        </ApolloProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  )
}

export default AppProvider