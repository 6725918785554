import { Button } from "@mui/material";
import React from "react";
import { useNetwork } from "wagmi";
import { switchNetwork } from '@wagmi/core';

//make props interface for window

//window: globalThis.Window & typeof globalThis
const AddTokenButton = () => {
  const { chain } = useNetwork();

    const tokenAddress= "0x3C856b95ADe474a321c92b5002436b7C57Fe5F96"; // The address of the token.
    const       tokenSymbol="LLM"; // A ticker symbol or shorthand, up to 5 characters.
    const       tokenDecimals= 18; // The number of decimals in the token.
    const        tokenImage= "https://storage.fleek-internal.com/d10cc1a7-7c9f-4ee6-8a94-e4a13807533d-bucket/Icons/llmlogo.png";
  const addToken = async () => { 
    
    if(window.ethereum){
    try {
      
      // Request user's account if not already connected const handleSwitchNetwork = async () => {
    
   
     
   
      const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });

if(chain?.id !== 0x55d76c9) {
      const network = await switchNetwork({
        chainId: 0x55d76c9,
      });}

      const isAdded = await window.ethereum.request({
       
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
         
          },
        
        },
      });

      if (isAdded) {
        console.log("Token added!");
      } else {
        console.log("Token not added!");
      }
    } catch (error) {
      console.log(error);
    }
}
  };

  return <Button variant="outlined" size="small" onClick={addToken}>Add Logical Mechanism (LLM) ERC-20 Token to Wallet</Button>;
};

export default AddTokenButton;
