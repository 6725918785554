export const EXPECTED_CHAIN_ID = Number(0x55d76c9)//Number(0x66eed)0xa4ba
export const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL
export const INFURA_KEY = process.env.REACT_APP_INFURA_KEY || ""
export const CORE_CONTRACT_ADDRESS = process.env.REACT_APP_CORE_CONTRACT_ADDRESS
export const MINT_CONTRACT_ADDRESS = process.env.REACT_APP_MINT_CONTRACT_ADDRESS
export const VOIDRITE_ADDRESS = process.env.REACT_APP_VOIDRITE_ADDRESS
export const TOKEN_URL = process.env.REACT_APP_TOKEN_URL
export const MEDIA_URL = process.env.REACT_APP_MEDIA_URL
export const GENERATOR_URL = process.env.REACT_APP_GENERATOR_URL
export const PROJECTS_PER_PAGE = 8
export const TOKENS_PER_PAGE = 9
export const MULTIPLY_GAS_LIMIT = 1.1
