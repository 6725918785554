import { Card, CardContent, CardHeader, Avatar, Typography, useTheme, styled, Box, Tooltip, Grid } from "@mui/material";
import RadiantGiftMintButton from "components/RadiantGiftMintButton";
import { BigNumber, ethers } from "ethers";
import { LinearProgress ,linearProgressClasses } from "@mui/material";
import { keyframes } from '@emotion/react';
import LLMMintButton from "./LLMMintButton";
import AddTokenButton from "./AddTokenButton";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(20deg);
  }

`;
const wobble = keyframes`
  0% { transform: rotate(0deg); }
 25% { transform: rotate(2deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-2deg); }
  100% { transform: rotate(0deg); }
`;
const rotate2 = keyframes`

  from {
    transform: rotate(20deg);
  }
  to {
    transform: rotate(0deg);
  }
`;
interface Props {


  mintPrice: number,
  ethPrice: number,
  balance: any,
  totalMinted: string
}
//925fa5
const StyledCard = styled(Card)(({ theme }) => ({
  
  transition: "transform 0.3s ease-in-out",
  '&:hover': {
    transform: "scale(1.05)",
    boxShadow: "0px 0px 15px  rgba(92, 179, 163, 1)",
    backgroundColor: "rgba(0, 0, 0, 1)",
    filter: 'drop-shadow(0px 0px 15px rgba(65, 179, 163, 1))',
    '& img': {
      animation: `${wobble} 2.5s linear infinite`,
     //146	95	165
      filter: 'drop-shadow(0px 0px 10px rgba(128, 226, 126, 0.9))',
      transition: 'filter 0.15s ease-in-out',}
  },

 // width: 460,
  minHeight: 480,
 // minWidth: 460,
  backgroundColor: "rgba(0, 0, 0, 0.05)",
  color: theme.palette.secondary.contrastText, 
  boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.4)",
  '& img': {
   
  
    filter: 'drop-shadow(0px 0px 10px rgba(128, 226, 126, 0.3))',
    transition: 'filter 0.3s ease-in-out',

  }
}));

const LLMMintCard = ({ mintPrice, ethPrice, balance,totalMinted }: Props) => {

  //const formattedPrice = mintPrice ? ethers.utils.formatUnits(mintPrice) : null;
 // console.log("formattedPrice", formattedPrice)

  const theme = useTheme();
 // const etherValueInETH = ethers.utils.fromWei("1000000000000000000", 'ether');
//https://docs.arbitrum.io/launch-orbit-chain/orbit-gentle-introduction
  return (
    <StyledCard >
        
        <CardHeader
      sx={{ backgroundColor: "rgba(0, 0, 0, 0.0)", color: theme.palette.primary.main }}    
        subheader={    <Typography color="#925fa5" variant="h1" fontSize={"2.75rem"}>
 
      </Typography>}  
        title={<Typography style={{fontSize:"2.45rem"}}  variant="h1"></Typography>}
      />
        
         <Typography color="#925fa5" variant="h1" fontSize={"2.75rem"}>Nether Bridge</Typography> 
 
         <Box ml={3} mr={3} mt={3}>
         <Grid container spacing={0}  style={{ display: 'flex', justifyContent: 'center' }}> {/* Increase or decrease the number to change the spacing */}
  <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
    <img src="./media/icons/eth.png" alt="ETH Logo" width={"90px"} />
  </Grid>
  <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
    <img src="./media/icons/right.svg" alt="Right" width={"90px"} />
  </Grid>
  <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
   
      <img src="./media/icons/llmlogo.png" width={"90px"} alt="LLM Logo" />
    
  </Grid>
</Grid>
    
    
    </Box>
    <Typography color="#925fa5" variant="h2" fontSize={"1 .55rem"}>ETH  :   LLM</Typography>     <Typography color="#925fa5"     variant="h3" style={{fontSize:"1.2rem"}}  component="p"><strong>
   1 LLM = ${(ethPrice ).toFixed(9)} </strong>
  
        </Typography>

      <CardContent sx={{ m:"0.5rem"}} >  
  
      
      
      
       
     <Box  mb={"2vh"} mt={"2vh"} mr={"4rem"}  ml={"4rem"}> 
<Typography mt={0.4} mb={0.4}  variant="h3" fontSize={"1.1rem"}> <strong>Utility:</strong>  AI Access</Typography>
<Typography mt={0.4} mb={0.4}  variant="h3" fontSize={"1.1rem"}> <strong>Chain:</strong> Sage Towers*</Typography>
      <Typography mt={0.4} mb={0.4}  variant="h3" fontSize={"1.1rem"}> <strong>Fees & Slippage:</strong> None </Typography>
     <Typography mt={0.4} mb={0.4}  variant="h3" fontSize={"1.1rem"}> <strong>One-Way Bridge:</strong> &lt; 60 sec  </Typography>
    
     

     </Box>
    

     <LLMMintButton mintPrice={mintPrice} ethPrice={ethPrice} balanceChange={balance} />
         
<Typography mt={"1rem"} mb={"-1rem"}  variant="body1" fontSize={"0.6rem"}> <strong>Buy Address- 0x7A9302622695De0724e03892BE56511789E0F987</strong></Typography>
     

      </CardContent>
    </StyledCard>
  );
};

export default LLMMintCard;

