import React, { useState, useEffect, useRef } from 'react';
import { Box, Card, Container, Link, List, ListItem, Typography, styled } from '@mui/material';
import Page from 'components/Page';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';
import { scroller } from 'react-scroll';
import { Button } from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import ItchButton from 'components/ItchButton';
import ItchAppButton from './ItchAppButton';
import ItchIframe from 'components/ItchIframe';
import AudioPlayer from 'components/AudioPlayer';
import YouTube from 'react-youtube';
//import ReactGA from "react-ga4";
type ContentData = {
  title: string;
  image: string;
  text: string;
  textAlign?: 'left' | 'center' | 'right';
  customStyle?: React.CSSProperties;
  buttonLink?: string;
  buttonLinkTarget?: '_blank' | '_self' | '_parent' | '_top';
  buttonText: string;
  imagePosition?: 'overlay' | 'nextTo';
};

const contentData: ContentData[] = [
  {
    title: 'Play Sage Towers',
    image: './media/tavern3.png',
    text: 'Download Sage Towers and login to get 20 free credits. Use the credits to talk to our "living NPCs" or make generative AI items. If you have any questions please join our Discord and talk to us, we love and need feedback!',
    textAlign: 'center',
    buttonText: 'Make Profile',
    buttonLink: 'https://sagetowers.com/profile',
    buttonLinkTarget: '_self',
    imagePosition: 'overlay',
    },
    {
      title: 'How to Install',
      image: './media/screenshot.jpg',
      text: 'Embark on an extraordinary journey in Sage Towers, an experience combining generative AI technology with endless planes of high fantasy. Download Sage Towers and login to get 20 free credits. Use the credits to talk to our "living NPCs" or make generative AI items. If you have any questions please join our Discord and talk to us, we love and need feedback!',
      textAlign: 'center',
      buttonText: 'Make Profile',
      buttonLink: 'https://sagetowers.com/profile',
      buttonLinkTarget: '_self',
      imagePosition: 'overlay',
      },
      {
        title: 'Whisperwind Tavern Arc',
        image: './media/basic16.png',
        text: 'Embark on an extraordinary journey in Sage Towers, an experience combining generative AI technology with endless planes of high fantasy. Download Sage Towers and login to get 20 free credits. Use the credits to talk to our "living NPCs" or make generative AI items. If you have any questions please join our Discord and talk to us, we love and need feedback!',
        textAlign: 'center',
        buttonText: 'Make Profile',
        buttonLink: 'https://sagetowers.com/profile',
        buttonLinkTarget: '_self',
        imagePosition: 'overlay',
        },
    
];

interface DownloadButtonProps {
  content: {
    buttonLink: string;
    buttonLinkTarget: string;
  };
  label: string;
}
const AnimatedBox = animated(Box);

interface AnimatedSectionProps {
  content: ContentData;
  id: string;
}
const AnimatedSection: React.FC<AnimatedSectionProps> = ({ content, id }) => {

  const [ref, inView] = useInView({ triggerOnce: true });
  const springProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(220px)',
    config: { duration: 900, tension: 290, friction: 40 },
  });
 
  const DownloadButton: React.FC<DownloadButtonProps> = ({ content, label }) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="large"
        sx={{
          textTransform: 'none',
          fontWeight: 'bold',
        }}
      >
        <a
          href={content.buttonLink}
          target={content.buttonLinkTarget}
          rel="noopener noreferrer"
          style={{
            textDecoration: 'none',
            color: 'inherit', // To inherit the color from the parent, i.e., Button
            display: 'block', // To make the entire button clickable
            height: '100%',
            width: '100%',
            textAlign: 'center'
          }}
        >
          {label}
        </a>
      </Button>
    );
  };
  
  const renderContent = () => (
    <Box  

      bgcolor="rgba(23, 23, 33, 0.85)"
      
      sx={{
        display: 'flex',
        flexDirection: content.imagePosition === 'nextTo' ? 'row' : 'column',
        alignItems: 'center',
        textAlign: content.textAlign || 'inherit',
        gap: 1,
        maxWidth: '95%',
        margin: '0 auto',
        padding: "0.5rem",
        borderRadius: "1px",
        boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.9)",
      }}
    >
      {content.imagePosition === 'nextTo' && (
      
        <img
          src={content.image}
          alt={content.title}
          style={{
            maxWidth: '50%',
            objectFit: 'contain',
            maxHeight: '75%',
          }}
        />
      )}
      <Box sx={{
        display: 'flex',
        flexDirection: content.imagePosition === 'nextTo' ? 'row' : 'column',
        alignItems: 'center',
        textAlign: content.textAlign || 'inherit',
        gap: 1,
        maxWidth: '85%',
        margin: '0 auto',
        padding: "0.5rem",
        borderRadius: "1px",
        
      }}>
        <Typography variant="h1">{content.title}</Typography>
      
  

 
    <AudioPlayer src="/media/intro.mp3" />
    
      <List>
        <ListItem>
          <Typography variant="body1">  
          In an untold realm, the Whisperwind Tavern, once part of the Radiant City, exists fragmented and shattered. Teleported to unknown dimensions during the Void cataclysm, it went missing, enveloped in the darkness of forgotten existences lost to time. However, a search party has recently discovered the tavern. Yet, within the exposed labyrinth of whispers, Ventus, the master elemental scientist, has vanished. Spreading waves of unrest through the shadowy halls of the tavern. </Typography>
        </ListItem>
        <ListItem>
          <Typography variant="body1">
          Your chapter in this endless saga starts here at the Whisperwind Tavern, filled with perpetual mysteries and unending adventures. Will you help to find Ventus,  unravel the concealed truths, and safeguard existence? Or will you fall to the madness of it all?   </Typography>
        </ListItem>
        <ListItem>
          <Typography variant="body1">
          The Sage Towers beckon, reach out and grab a hold of fate. Your journey begins now!
          </Typography>
        </ListItem>
      </List>
  
        </Box>
        
     
    </Box>
  );


  return (
    <AnimatedBox
      ref={ref}
      id={id}
      sx={{
        boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.9)",
        margin: '1 auto',
        padding: "0.5rem",
        borderRadius: "3px",
        minHeight: '60vh',
        display: 'grid',
        placeItems: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: content.imagePosition === 'overlay' ? `url(${content.image})` : undefined,
        ...content.customStyle,
      }}
      style={springProps}
    >
      {content.imagePosition === 'overlay' ? (
        renderContent()
      ) : (
        <Box
          sx={{
            boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.9)",
            margin: '1 auto',
            padding: "0.5rem",
            borderRadius: "3px",
            display: 'grid',
            placeItems: 'center',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${content.image})`,
            width: '100%',
            height: '100%',
          }}
        >
          {renderContent()}
        </Box>
      )}
    </AnimatedBox>
  );
};
const AnimatedSectionInstall: React.FC<AnimatedSectionProps> = ({ content, id }) => {

  const [ref, inView] = useInView({ triggerOnce: true });
  const springProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(220px)',
    config: { duration: 900, tension: 290, friction: 40 },
  });
 
  const DownloadButton: React.FC<DownloadButtonProps> = ({ content, label }) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="large"
        sx={{
          textTransform: 'none',
          fontWeight: 'bold',
        }}
      >
        <a
          href={content.buttonLink}
          target={content.buttonLinkTarget}
          rel="noopener noreferrer"
          style={{
            textDecoration: 'none',
            color: 'inherit', // To inherit the color from the parent, i.e., Button
            display: 'block', // To make the entire button clickable
            height: '100%',
            width: '100%',
            textAlign: 'center'
          }}
        >
          {label}
        </a>
      </Button>
    );
  };
  
  const renderContent = () => (
    <Box  

      bgcolor="rgba(23, 23, 33, 0.85)"
      
      sx={{
        display: 'flex',
        flexDirection: content.imagePosition === 'nextTo' ? 'row' : 'column',
        alignItems: 'center',
        textAlign: content.textAlign || 'inherit',
        gap: 1,
        maxWidth: '95%',
        margin: '0 auto',
        padding: "0.5rem",
        borderRadius: "1px",
        boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.9)",
      }}
    >
      {content.imagePosition === 'nextTo' && (
      
        <img
          src={content.image}
          alt={content.title}
          style={{
            maxWidth: '50%',
            objectFit: 'contain',
            maxHeight: '75%',
          }}
        />
      )}
      <Box sx={{
        display: 'flex',
        flexDirection: content.imagePosition === 'nextTo' ? 'row' : 'column',
        alignItems: 'center',
        textAlign: content.textAlign || 'inherit',
        gap: 1,
        maxWidth: '85%',
        margin: '0 auto',
        padding: "0.5rem",
        borderRadius: "1px",
        
      }}>
        
      <Typography sx={{mt:"2rem"}} variant="h1"  gutterBottom>
        How to Install Sage Towers:
      </Typography>
      <List>
        <ListItem>
          <Typography variant="body1">  
            <strong>1. Unzip the File:</strong> Locate the downloaded zip file and unzip it. Double click <code>"Sage Towers.exe"</code> to begin.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography variant="body1">
            <strong>2. Login:</strong> Hit the login button to connect with your Discord in the browser. If it’s your first time, we will create an account for you and grant you credits to interact with the large language models.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography variant="body1">
            <strong>3. Return to the Game:</strong> Once the login flow is completed, return back to the game.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography variant="body1">
            <strong>4. Enjoy!</strong> Use a microphone to talk to the NPCs. After pressing the voice chat hotkey, make sure to watch for the Mic icon before you start talking. You can send voice messages up to 10 seconds long.
          </Typography>
        </ListItem>
      </List>
  
        </Box>
        
      </Box>
    
  );


  return (
    <AnimatedBox
      ref={ref}
      id={id}
      sx={{
        boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.9)",
        margin: '1 auto',
        padding: "0.5rem",
        borderRadius: "3px",
        minHeight: '60vh',
        display: 'grid',
        placeItems: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: content.imagePosition === 'overlay' ? `url(${content.image})` : undefined,
        ...content.customStyle,
      }}
      style={springProps}
    >
      {content.imagePosition === 'overlay' ? (
        renderContent()
      ) : (
        <Box
          sx={{
            boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.9)",
            margin: '1 auto',
            padding: "0.5rem",
            borderRadius: "3px",
            display: 'grid',
            placeItems: 'center',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${content.image})`,
            width: '100%',
            height: '100%',
          }}
        >
          {renderContent()}
        </Box>
      )}
    </AnimatedBox>
  );
};
const AnimatedSectionCustom: React.FC<AnimatedSectionProps> = ({ content, id }) => {

  const [ref, inView] = useInView({ triggerOnce: true });
  const springProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(220px)',
    config: { duration: 900, tension: 290, friction: 40 },
  });
 
  const DownloadButton: React.FC<DownloadButtonProps> = ({ content, label }) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="large"
        sx={{
          textTransform: 'none',
          fontWeight: 'bold',
        }}
      >
        <a
          href={content.buttonLink}
          target={content.buttonLinkTarget}
          rel="noopener noreferrer"
          style={{
            textDecoration: 'none',
            color: 'inherit', // To inherit the color from the parent, i.e., Button
            display: 'block', // To make the entire button clickable
            height: '100%',
            width: '100%',
            textAlign: 'center'
          }}
        >
          {label}
        </a>
      </Button>
    );
  };
  
  const renderContent = () => (
    <Box  

      bgcolor="rgba(23, 23, 33, 0.85)"
      
      sx={{
        display: 'flex',
        flexDirection: content.imagePosition === 'nextTo' ? 'row' : 'column',
        alignItems: 'center',
        textAlign: content.textAlign || 'inherit',
        gap: 1,
        maxWidth: '95%',
        margin: '0 auto',
        padding: "0.5rem",
        borderRadius: "1px",
        boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.9)",
      }}
    >
      {content.imagePosition === 'nextTo' && (
      
        <img
          src={content.image}
          alt={content.title}
          style={{
            maxWidth: '50%',
            objectFit: 'contain',
            maxHeight: '75%',
          }}
        />
      )}
      <Box sx={{
        display: 'flex',
        flexDirection: content.imagePosition === 'nextTo' ? 'row' : 'column',
        alignItems: 'center',
        textAlign: content.textAlign || 'inherit',
        gap: 1,
        maxWidth: '85%',
        margin: '0 auto',
        padding: "0.5rem",
        borderRadius: "1px",
        
      }}>
        <Typography variant="h1">{content.title}</Typography>
      
      
        <Typography variant="h2">Version 0.0.3</Typography>
        <Box marginTop={"0.25rem"} marginBottom={"0.25rem"}>
        <Box margin={"0.25rem"}>
        <DownloadButton  content={{ buttonLink: 'https://ipfs.io/ipfs/QmeZGc87xawNhXbqF67QyrTvA9knMpwLpUtvRMUGhd175Z', buttonLinkTarget: '_self' }} label="Download RAR" />

        </Box>
       
<Box margin={"1rem"} >
        <DownloadButton content={{ buttonLink: 'https://amazastrophic.itch.io/sagetowers', buttonLinkTarget: '_blank' }} label="Download from Itch.io" />
</Box>

<Link href="https://itch.io/app" underline="none" target="_blank" rel="noopener noreferrer">

      <Typography variant="h2" sx={{ m: 1 }}>
        Use the Itch App for easy updating! 
      </Typography>
    </Link>
    <Typography variant="body1">{content.text}</Typography>
  
        </Box>
        
      </Box>
    </Box>
  );


  return (
    <AnimatedBox
      ref={ref}
      id={id}
      sx={{
        boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.9)",
        margin: '1 auto',
        padding: "0.5rem",
        borderRadius: "3px",
        minHeight: '60vh',
        display: 'grid',
        placeItems: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: content.imagePosition === 'overlay' ? `url(${content.image})` : undefined,
        ...content.customStyle,
      }}
      style={springProps}
    >
      {content.imagePosition === 'overlay' ? (
        renderContent()
      ) : (
        <Box
          sx={{
            boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.9)",
            margin: '1 auto',
            padding: "0.5rem",
            borderRadius: "3px",
            display: 'grid',
            placeItems: 'center',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${content.image})`,
            width: '100%',
            height: '100%',
          }}
        >
          {renderContent()}
        </Box>
      )}
    </AnimatedBox>
  );
};




const AnimatedSectionYoutube = () => {

  const videoOptions = {
    height: 179*1.5,
    width:  320*1.5,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
     // .autoplay: 1, // Autoplay the video on load
    },
  };
  
  const YoutubeEmbed = () => {
  return (
    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
      <iframe
        src={`https://www.youtube.com/embed/tVdcnAu59d0`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube video player"
        style={{ border: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
      ></iframe>
    </div>
  );
};
const DownloadButton: React.FC<DownloadButtonProps> = ({ content, label }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      sx={{
        textTransform: 'none',
        fontWeight: 'bold',
      }}
    >
      <a
        href={content.buttonLink}
        target={content.buttonLinkTarget}
        rel="noopener noreferrer"
        style={{
          textDecoration: 'none',
          color: 'inherit', // To inherit the color from the parent, i.e., Button
          display: 'block', // To make the entire button clickable
          height: '100%',
          width: '100%',
          textAlign: 'center'
        }}
      >
        {label}
      </a>
    </Button>
  );
};


  const [ref, inView] = useInView({ triggerOnce: true });
  const springProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(220px)',
    config: { duration: 900, tension: 290, friction: 40 },
  });
 
  
  const renderContent = () => (
    <Box  

      bgcolor="rgba(23, 23, 33, 0.85)"
      
      sx={{
        display: 'flex',
        flexDirection: contentData[0].imagePosition === 'nextTo' ? 'row' : 'column',
        alignItems: 'center',
        textAlign: contentData[0].textAlign || 'inherit',
        gap: 1,
        maxWidth: '95%',
        margin: '0 auto',
        padding: "0.5rem",
        borderRadius: "1px",
        boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.9)",
      }}
    >
      {contentData[0].imagePosition === 'nextTo' && (
      
        <img
          src={contentData[0].image}
          alt={contentData[0].title}
          style={{
            maxWidth: '50%',
            objectFit: 'contain',
            maxHeight: '75%',
          }}
        />
      )}
      <Box sx={{
        display: 'flex',
        flexDirection: contentData[0].imagePosition === 'nextTo' ? 'row' : 'column',
        alignItems: 'center',
        textAlign: contentData[0].textAlign || 'inherit',
        gap: 1,
        maxWidth: '85%',
        margin: '0 auto',
        padding: "0.5rem",
        borderRadius: "1px",
        width:'85%'
      }}>
         <Typography variant="h1">Play Sage Towers</Typography>
        <YouTube videoId="tVdcnAu59d0" opts={videoOptions} />
        <Box margin={"1rem"} >
        <DownloadButton content={{ buttonLink: 'https://amazastrophic.itch.io/sagetowers', buttonLinkTarget: '_blank' }} label="Download from Itch.io" />
      
        
</Box>
        
      </Box>
    </Box>
  );


  return (
    <AnimatedBox
      ref={ref}
      id={"4"}
      sx={{
        boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.9)",
        margin: '1 auto',
        padding: "0.5rem",
        borderRadius: "3px",
        minHeight: '60vh',
        display: 'grid',
        placeItems: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: contentData[0].imagePosition === 'overlay' ? `url(${contentData[0].image})` : undefined,
        ...contentData[0].customStyle,
      }}
      style={springProps}
    >
      {contentData[0].imagePosition === 'overlay' ? (
        renderContent()
      ) : (
        <Box
          sx={{
            boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.9)",
            margin: '1 auto',
            padding: "0.5rem",
            borderRadius: "3px",
            display: 'grid',
            placeItems: 'center',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${contentData[0].image})`,
            width: '100%',
            height: '100%',
          }}
        >
          {renderContent()}
        </Box>
      )}
    </AnimatedBox>
  );
};


const StyledCard = styled(Card)(({ theme }) => ({
 
  margin: "1rem",
  minWidth: 260,
  backgroundColor: "rgba(0, 0, 0, 0)",
  color: "rgba(0, 0, 0, 0)", 
}));
const GamePage: React.FC = () => {

  return (

    <Page >
       <LoadingScreen />
       <StyledCard>
       
        <AnimatedSectionCustom
          key={0}
          id={`section-${0}`}
          content={contentData[0]}
        />
         <AnimatedSectionInstall
          key={1}
          id={`section-${1}`}
          content={contentData[1]}
        />  
         <AnimatedSection
          key={2}
          id={`section-${2}`}
          content={contentData[2]}
        />  
        </StyledCard>
         
    </Page>
   
  );
};
export default GamePage;