import React from 'react';
import { Button } from '@mui/material';
import { switchNetwork } from '@wagmi/core';

const SwitchNetworkButton = () => {
  const handleSwitchNetwork = async () => {
    try {
      const network = await switchNetwork({
        chainId: 1,
      });
      // Handle the response here if needed
      console.log('Network switched:', network);
    } catch (error) {
      console.error('Failed to switch network:', error);
    }
  };

  return (
    <Button variant="contained" color="primary" onClick={handleSwitchNetwork}>
      Switch to Mainnet
    </Button>
  );
};

export default SwitchNetworkButton;
