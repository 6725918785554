import { Link, useParams } from "react-router-dom";
import Page from "components/Page";
import { Box, Button, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import {
  useBalance,
  useAccount,
  useConnect,
  useDisconnect,
  useSignMessage,
  useContractReads
} from 'wagmi'

import VoidriteABI from 'abi/Voidrite.json' // Make sure you import the ABI of your contract here

import { BigNumber, ethers } from 'ethers'
import { ConnectButton } from "@rainbow-me/rainbowkit";
import Connect from "components/Connect";
import { Avatar } from '@mui/material';
import React, { useEffect, useState } from "react";

import InfoCard from "components/InfoCard";
import theme from "theme";
import Terms from "components/Terms";
import { Type } from "react-toastify/dist/utils";


const LicensePage = () => {
  
  return (
    <Page>
      <Box
        bgcolor="rgba(23, 23, 33, 0.85)"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 1.5,
          width: "100%",
          padding: "0.5rem",
          borderRadius: "3px",
        }}
      >
        <Typography variant="h1">
        Embrace Freedom: AI-Generated Content CC0
        </Typography>
        <Link to={"https://creativecommons.org/share-your-work/public-domain/cc0/"}>
       <Button>
        Click here to see the full CC0 license
        </Button>
        </Link>
        <Terms
        title="1. Introduction"
        text="The digital landscape is evolving at breakneck speed, with AI-generated content emerging as a revolutionary force in media creation and consumption. Amid this transformation, complex questions around ownership, copyrights, and licensing have surfaced. Sage Towers, an innovative metaverse project, has confronted these challenges head-on, adopting a daring strategy: making all AI-generated content in its realm Creative Commons Zero, or CC0."   
         />
                 <Terms
        title="2. Understanding CC0"
        text="Creative Commons Zero (CC0) is a universal tool that allows creators to relinquish all their rights to their works, effectively positioning these creations within the public domain. This means anyone, anywhere, can freely use, adapt, and distribute these works without requiring permission or providing attribution to the original creator." />
                 <Terms
        title="3. The Rationale for CC0"
        text="Leveraging CC0 for AI-generated content offers manifold advantages. It fuels an environment of openness and collaboration, serving as a catalyst for innovation and inclusivity. Additionally, it unravels the Gordian knot of ownership complexities associated with AI-generated content. Sage Towers, through the implementation of CC0 licenses, provides a practical solution to this intricate issue, fostering simplicity and clarity."   
         />
                 <Terms
        title="4. Sage Towers' Implementation of CC0"
        text="Sage Towers has pledged to apply CC0 to all AI-generated content within its metaverse ambit, a commitment in sync with the organization's ethos of promoting a community-centric environment and empowering users with genuine ownership. By granting free access to AI-generated content, Sage Towers stimulates creativity, fosters collaboration, and facilitates a robust exchange of ideas among its users."   
         />
                 <Terms
        title="5. Implications for the AI and Metaverse Landscape"
        text="Sage Towers' adoption of CC0 for AI-generated content carries profound implications for the AI community, end-users, and the broader metaverse ecosystem. It establishes a compelling precedent that could inspire other entities to follow suit, potentially catalyzing a more collaborative and transparent digital environment. This progressive approach also addresses the murky legal aspects surrounding AI-generated content, forging a path towards a more accessible and transparent future."   
         />
                 <Terms
        title="6. CC0 FTW"
        text="Sage Towers' pioneering decision to dedicate all AI-generated content to CC0 is a major stride towards nurturing a culture of openness, collaboration, and universal access within the metaverse. By championing this approach, we can harness the immense potential of AI-generated content to the benefit of creators, users, and the wider digital ecosystem. We are hopeful that other organizations will echo this initiative, contributing to a future of greater innovation and inclusivity."   
         />

       
       </Box>
    </Page>
  );
};

export default LicensePage;
