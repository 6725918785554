import { useParams } from "react-router-dom";
import Page from "components/Page";
import { Box, Button, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import {
  useBalance,
  useAccount,
  useConnect,
  useDisconnect,
  useSignMessage,
  useContractReads
} from 'wagmi'

import VoidriteABI from 'abi/Voidrite.json' // Make sure you import the ABI of your contract here

import { BigNumber, ethers } from 'ethers'
import { ConnectButton } from "@rainbow-me/rainbowkit";
import Connect from "components/Connect";
import { Avatar } from '@mui/material';
import React, { useEffect, useState } from "react";

import InfoCard from "components/InfoCard";
import theme from "theme";
import Terms from "components/Terms";
import { Type } from "react-toastify/dist/utils";


const GuidelinesPage = () => {
  
  return (
    <Page>
      <Box
        bgcolor="rgba(23, 23, 33, 0.85)"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 1.5,
          width: "100%",
          padding: "0.5rem",
          borderRadius: "3px",
        }}
      >
        <Typography variant="h1">
        Community Guidelines
        </Typography>
        <Terms
        title="1. Be Respectful and Considerate"
        text="Treat all players, staff, and community members with respect and courtesy. Avoid using offensive language, engaging in harassment, or promoting hate or discrimination. Remember that everyone is here to enjoy the Sage Towers experience, and creating a welcoming environment is essential."/>
           <Terms
        title="2. No Spamming or Advertising"
        text="Refrain from spamming messages, images, or links in any of the server channels. Additionally, do not advertise other servers, products, or services without prior permission from the Sage Towers staff."   
         />
                 <Terms
        title="3. Keep Content Appropriate"
        text="Ensure that all content shared within the server, including text, images, and links, is suitable for players of all ages. Mature themes, explicit content, and offensive material are strictly prohibited."   
         />
                 <Terms
        title="4. Follow Channel-Specific Guidelines"
        text="Each channel within the Sage Towers server may have its own set of guidelines or rules. Be sure to familiarize yourself with these guidelines and adhere to them when participating in discussions or activities within each channel."   
         />
                    <Terms
        title="5. No Cheating, Exploiting, or Hacking"
        text="Using cheats, hacks, or exploiting game mechanics to gain an unfair advantage is strictly prohibited. Players found engaging in such activities will be subject to disciplinary action, including temporary or permanent bans."   
         />
                    <Terms
        title="6. Respect Player Privacy"
        text="Do not share personal information about other players without their consent. This includes real names, contact information, or any other sensitive information that could compromise their privacy."   
         />
                        <Terms
        title="7. Participate in Events Responsibly"
        text="When joining events, contests, or activities within the Sage Towers community, participate in a fair and respectful manner. Follow event-specific rules and guidelines, and respect the decisions of event organizers and staff."   
         />
       
       <Terms
        title="8. Report Rule Violations"
        text="If you witness any rule violations or inappropriate behavior, please report it to the Sage Towers staff using the appropriate reporting channels. Your cooperation helps maintain a safe and enjoyable environment for all players."   
         />
       
       <Terms
        title="9. Follow Staff Instructions"
        text="Respect the authority of Sage Towers staff members and follow their instructions when necessary. If you have concerns or disagreements with staff decisions, address them in a respectful and constructive manner."   
         />
          <Terms
        title="10. Encourage In-Character (IC) Interactions"
        text="While in roleplay areas or events, we encourage you to remain in-character as much as possible. This means speaking, acting, and making decisions as your character would within the Sage Towers universe. However, we understand that occasional out-of-character (OOC) interactions may occur. Be mindful of the impact of breaking character, especially when interacting with living NPCs, as it could have consequences within the game."/>
           <Terms
        title="11. Respect Lore and Setting"
        text="Familiarize yourself with the established history, themes, and setting of Sage Towers. Ensure your character's backstory, actions, and interactions align with the metaverse's lore. This helps maintain immersion and consistency within the roleplay community."   
         />
             <Terms
        title="12. Separate IC and OOC"
        text="Keep in-character actions and consequences separate from out-of-character feelings and relationships. Understand that IC conflicts and disagreements are part of the roleplay experience and should not be taken personally or affect OOC interactions.       "   
         />
                 <Terms
        title="13. Communicate OOC When Necessary"
        text="If you need to communicate out-of-character information, use designated OOC channels or clearly indicate your message as OOC (e.g., by using double parentheses ((like this)) ). This helps prevent confusion and maintain immersion during roleplay sessions."   
         />
                 <Terms
        title="14. No Godmodding or Metagaming"
        text="Godmodding refers to controlling another player's character or actions without their consent or making your character invincible or all-powerful. Metagaming involves using out-of-character knowledge to influence in-character decisions. Both practices are prohibited, as they disrupt the balance and fairness of the roleplay experience."   
         />
                    <Terms
        title="15. Be Respectful and Considerate"
        text="Treat all players with respect and courtesy, both in-character and out-of-character. Avoid using offensive language, engaging in harassment, or promoting hate or discrimination. Remember that everyone is here to enjoy the roleplay experience, and creating a welcoming environment is essential."   
         />
                    <Terms
        title="16. No Mature Themes"
        text="Mature themes and content are strictly prohibited within the Sage Towers roleplay community. Ensure that all roleplay scenarios and interactions remain appropriate and suitable for players of all ages."   
         />
                        <Terms
        title=""
        text="By following these guidelines, you can contribute to a vibrant and immersive roleplay experience within the Sage Towers Metaverse, fostering creativity, collaboration, and unforgettable stories while emphasizing the importance of AI-driven LLMs."   
         />
    
       
       
       </Box>
    </Page>
  );
};

export default GuidelinesPage;
