import * as React from "react"
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from "react-router-dom"
import { createTheme, PaletteColor } from "@mui/material/styles"
import { LinkProps } from "@mui/material/Link"

declare module "@mui/material/styles" {
  interface Palette {
    upcoming: PaletteColor
  }
  interface PaletteOptions {
    upcoming: PaletteColor
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    upcoming: true
  }
}

const LinkBehavior = React.forwardRef<
  any,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />
})

const { palette } = createTheme()

const theme = createTheme({
  palette: {
    success: {
      main: "#009688"
    },
    error: {
      main: "#F44336"
    },
    upcoming: palette.augmentColor({
      color: {
        main: "#673AB7"
      }
    }),
    primary: {
      main: "#80e27e",
      contrastText: "#232333",
    },
    secondary: {
      main: "#232333",
      contrastText: "#41b3a3",
    }
  },
  typography: {
    fontFamily: [
      "Cinzel",
      "Geometric",
      "Segoe UI",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
      "Red Hat Text"
    ].join(","),
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior
      } as LinkProps
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          fontFamily: "Red Hat Text",
      
          alignContent: "right",
          alignitems: "right",
          fontSize: "0.7rem",
          color: "#80e27e ", 
          boxShadow: "0px 0px  25px rgba(128, 226, 126, 0.5)", 
          borderRadius: "2",
          paddingTop: 0, 
          paddingBottom: 0,
        },
      },
    },
  },
})

theme.typography.h1 = {
  fontFamily: "Cinzel",
  fontWeight: "bold",
  fontSize: "36px",
  color: "#80e27e",
};
theme.typography.h2 = {
  fontFamily: "Red Hat Text",
  fontWeight: "bold",
  fontSize: "24px",
  color: "#80e27e ", 
};
theme.typography.body1 = {
  fontFamily: "Red Hat Text",
  fontWeight: "regular",
  fontSize: "16px",
  color: "#41b3a3", 
};

export default theme;
