import React, { useState, useEffect, useRef } from 'react';
import { Box, Card, Typography, styled } from '@mui/material';
import Page from 'components/Page';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';
import { scroller } from 'react-scroll';
import { Button } from '@mui/material';
import LoadingScreen from 'components/LoadingScreen';
import YouTube from 'react-youtube';
//import ReactGA from "react-ga4";
type ContentData = {
  title: string;
  image: string;
  text: string;
  textAlign?: 'left' | 'center' | 'right';
  customStyle?: React.CSSProperties;
  buttonLink?: string;
  buttonLinkTarget?: '_blank' | '_self' | '_parent' | '_top';
  buttonText: string;
  imagePosition?: 'overlay' | 'nextTo';
};
interface DownloadButtonProps {
  content: {
    buttonLink: string;
    buttonLinkTarget: string;
  };
  label: string;
}
const contentData: ContentData[] = [
  {
    title: 'Play Sage Towers',
    image: './media/whisperwind.jpg',
    text: 'Experience real-time multiplayer voice chat where generative agents transform voice data into text, process it through an LLM, and respond via a text-to-speech service—all within about 5 seconds!',
    textAlign: 'center',
    buttonText: 'Get 20 Credits Free',
    buttonLink: '/game',
    buttonLinkTarget: '_self',
    imagePosition: 'overlay',
    },
  {
    title: 'Nether Bridge is connected',
    image: './media/netherwide.png',
    text: 'Cross the boundaries of blockchain with the Nether Bridge. Convert your ETH into Logical Mechanism tokens, and immerse yourself in the AI-driven marvels of Sage Towers. Secure, reliable, and ready for you.',
    textAlign: 'center',
    buttonText: 'Bridge for LLM',
    buttonLink: '/llm',
    buttonLinkTarget: '_self',
    imagePosition: 'overlay',
    },
    {
    title: 'Voidrite: The Cataclysm Resource',
    image: './media/deepcave.png',
    text: 'Discovered after the Void Cataclysm, it is imbued with raw elemental energy from the fabric of the universe itself. Due to its scarcity and unique properties, it has become highly sought after by crafters and engineers.',
    textAlign: 'left',
    customStyle: {
    justifyContent: 'left',
    },
    buttonText: 'Get Voidrite',
    buttonLink: './voidrite',
    buttonLinkTarget: '_self',
    imagePosition: 'overlay',
    },
    {
    title: 'The Metaverse Ready to Embrace AI and Blockchain',
    image: './media/tavern.png',
    text: 'Combining AI-driven gameplay, blockchain technology, and the perfect blend of survival and casual games, Sage Towers is set to capture the imagination of both casual users and hardcore gamers alike.',
    textAlign: 'right',
    customStyle: {
    justifyContent: 'flex-end',
    },
    buttonText: 'Latest News',
    buttonLink: 'https://medium.com/@sagetowers',
    buttonLinkTarget: '_blank',
    imagePosition: 'overlay',
    }
   
];

const AnimatedBox = animated(Box);

interface AnimatedSectionProps {
  content: ContentData;
  id: string;
}

const AnimatedSection: React.FC<AnimatedSectionProps> = ({ content, id }) => {

  const [ref, inView] = useInView({ triggerOnce: true });
  const springProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(220px)',
    config: { duration: 900, tension: 290, friction: 40 },
  });
 
  const handleClick = () => {
  window.open(content.buttonLink, content.buttonLinkTarget, 'noopener,noreferrer');}

  const renderContent = () => (
    <Box  

      bgcolor="rgba(23, 23, 33, 0.85)"
      
      sx={{
        display: 'flex',
        flexDirection: content.imagePosition === 'nextTo' ? 'row' : 'column',
        alignItems: 'center',
        textAlign: content.textAlign || 'inherit',
        gap: 1,
        maxWidth: '95%',
        margin: '0 auto',
        padding: "0.5rem",
        borderRadius: "1px",
        boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.9)",
      }}
    >
      {content.imagePosition === 'nextTo' && (
      
        <img
          src={content.image}
          alt={content.title}
          style={{
            maxWidth: '50%',
            objectFit: 'contain',
            maxHeight: '75%',
          }}
        />
      )}
      <Box sx={{
        display: 'flex',
        flexDirection: content.imagePosition === 'nextTo' ? 'row' : 'column',
        alignItems: 'center',
        textAlign: content.textAlign || 'inherit',
        gap: 1,
        maxWidth: '85%',
        margin: '0 auto',
        padding: "0.5rem",
        borderRadius: "1px",
        
      }}>
        <Typography variant="h1">{content.title}</Typography>
        <Typography variant="body1">{content.text}</Typography>
        <Box marginTop={"1rem"} marginBottom={"1rem"}>
        <Button
           onClick={handleClick}
          variant="contained"
          color="primary"
          size="large"
          sx={{
            textTransform: 'none',
            fontWeight: 'bold',
          }}
        >
          {content.buttonText}
        </Button>
        </Box>
      </Box>
    </Box>
  );


  return (
    <AnimatedBox
      ref={ref}
      id={id}
      sx={{
        boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.9)",
        margin: '1 auto',
        padding: "0.5rem",
        borderRadius: "3px",
        minHeight: '60vh',
        display: 'grid',
        placeItems: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: content.imagePosition === 'overlay' ? `url(${content.image})` : undefined,
        ...content.customStyle,
      }}
      style={springProps}
    >
      {content.imagePosition === 'overlay' ? (
        renderContent()
      ) : (
        <Box
          sx={{
            boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.9)",
            margin: '1 auto',
            padding: "0.5rem",
            borderRadius: "3px",
            display: 'grid',
            placeItems: 'center',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${content.image})`,
            width: '100%',
            height: '100%',
          }}
        >
          {renderContent()}
        </Box>
      )}
    </AnimatedBox>
  );
};
const StyledCard = styled(Card)(({ theme }) => ({
 
  margin: "1rem",
  minWidth: 260,
  backgroundColor: "rgba(0, 0, 0, 0)",
  color: "rgba(0, 0, 0, 0)", 
}));

const AnimatedSectionYoutube = () => {

  const videoOptions = {
    height: 179*1.5,
    width:  320*1.5,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
     // .autoplay: 1, // Autoplay the video on load
    },
  };
  
  const YoutubeEmbed = () => {
  return (
    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
      <iframe
        src={`https://www.youtube.com/embed/tVdcnAu59d0`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube video player"
        style={{ border: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
      ></iframe>
    </div>
  );
};
const DownloadButton: React.FC<DownloadButtonProps> = ({ content, label }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      sx={{
        textTransform: 'none',
        fontWeight: 'bold',
      }}
    >
      <a
        href={content.buttonLink}
        target={content.buttonLinkTarget}
        rel="noopener noreferrer"
        style={{
          textDecoration: 'none',
          color: 'inherit', // To inherit the color from the parent, i.e., Button
          display: 'block', // To make the entire button clickable
          height: '100%',
          width: '100%',
          textAlign: 'center'
        }}
      >
        {label}
      </a>
    </Button>
  );
};


  const [ref, inView] = useInView({ triggerOnce: true });
  const springProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(220px)',
    config: { duration: 900, tension: 290, friction: 40 },
  });
 
  
  const renderContent = () => (
    <Box  

      bgcolor="rgba(23, 23, 33, 0.85)"
      
      sx={{
        display: 'flex',
        flexDirection: contentData[0].imagePosition === 'nextTo' ? 'row' : 'column',
        alignItems: 'center',
        textAlign: contentData[0].textAlign || 'inherit',
        gap: 1,
        maxWidth: '95%',
        margin: '0 auto',
        padding: "0.5rem",
        borderRadius: "1px",
        boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.9)",
      }}
    >
      {contentData[0].imagePosition === 'nextTo' && (
      
        <img
          src={contentData[0].image}
          alt={contentData[0].title}
          style={{
            maxWidth: '50%',
            objectFit: 'contain',
            maxHeight: '75%',
          }}
        />
      )}
      <Box sx={{
        display: 'flex',
        flexDirection: contentData[0].imagePosition === 'nextTo' ? 'row' : 'column',
        alignItems: 'center',
        textAlign: contentData[0].textAlign || 'inherit',
        gap: 1,
        maxWidth: '85%',
        margin: '0 auto',
        padding: "0.5rem",
        borderRadius: "1px",
        width:'85%'
      }}>
         <Typography variant="h1">Play Sage Towers</Typography>
        <YouTube videoId="tVdcnAu59d0" opts={videoOptions} />
        <Box margin={"1rem"} >
        <DownloadButton content={{ buttonLink: ' /game', buttonLinkTarget: '_self' }} label="Get 20 Credits Free" />
      
        
</Box>
        
      </Box>
    </Box>
  );


  return (
    <AnimatedBox
      ref={ref}
      id={"4"}
      sx={{
        boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.9)",
        margin: '1 auto',
        padding: "0.5rem",
        borderRadius: "3px",
        minHeight: '60vh',
        display: 'grid',
        placeItems: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: contentData[0].imagePosition === 'overlay' ? `url(${contentData[0].image})` : undefined,
        ...contentData[0].customStyle,
      }}
      style={springProps}
    >
      {contentData[0].imagePosition === 'overlay' ? (
        renderContent()
      ) : (
        <Box
          sx={{
            boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.9)",
            margin: '1 auto',
            padding: "0.5rem",
            borderRadius: "3px",
            display: 'grid',
            placeItems: 'center',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${contentData[0].image})`,
            width: '100%',
            height: '100%',
          }}
        >
          {renderContent()}
        </Box>
      )}
    </AnimatedBox>
  );
};


const LandingPage: React.FC = () => {

  return (

    <Page >
       <LoadingScreen />
       <StyledCard>
        <AnimatedSectionYoutube
        /></StyledCard>
           <StyledCard>
          <AnimatedSection
          key={1}
          id={`section-${1}`}
          content={contentData[1]}
        /></StyledCard>
           <StyledCard>
        <AnimatedSection
          key={2}
          id={`section-${2}`}
          content={contentData[2]}
        /></StyledCard>
           <StyledCard>
          <AnimatedSection
          key={3}
          id={`section-${3}`}
          content={contentData[3]}
        /></StyledCard>
    </Page>
   
  );
};
export default LandingPage;