import { Card, CardContent, CardHeader, Avatar, Typography, useTheme, styled, Box } from "@mui/material";
import VoidriteMintButton from "components/VoidriteMintButton";
import { BigNumber, ethers } from "ethers";
import { LinearProgress ,linearProgressClasses } from "@mui/material";
import VoidriteSellButton from "./VoidriteSellButton";
import VoidriteBuyButton from "./VoidriteBuyButton";

interface Props {
    isConnected: boolean,
    voidritePoolBalance: BigNumber,
    buyPrice: BigNumber,
    maxBuy: BigNumber,
    balance: string
}

const StyledCard = styled(Card)(({ theme }) => ({
  transition: "transform 0.3s ease-in-out",
  '&:hover': {
    transform: "scale(1.05)",
    boxShadow: "0px 0px 15px  rgba(65, 179, 163, 1)",
    backgroundColor: "rgba(0, 0, 0, 1)",
    filter: 'drop-shadow(0px 0px 15px rgba(65, 179, 163, 1))',
    '& img': {
      width: '230px',
      objectFit: 'contain',
      filter: 'drop-shadow(0px 0px 10px rgba(128, 226, 126, 0.9))',
      transition: 'filter 0.15s ease-in-out',}
  },
  height: 480,
  minHeight: 480,
  minWidth: 260,
  backgroundColor: "rgba(0, 0, 0, 0.05)",
  color: theme.palette.secondary.contrastText, 
  boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.4)",
  '& img': {
    width: '230px',
    objectFit: 'contain',
    filter: 'drop-shadow(0px 0px 10px rgba(128, 226, 126, 0.3))',
    transition: 'filter 0.3s ease-in-out',

  }
}));

const VoidriteBuyCard = ({ isConnected, voidritePoolBalance, buyPrice, maxBuy,balance}: Props) => {
  const formattedPrice = buyPrice ? ethers.utils.formatUnits(buyPrice).toString().substring(0,7) : 0;
  const currentSupply = voidritePoolBalance ? voidritePoolBalance.toString() : "0";
  const balancez = balance ? balance : "0.00";
  const theme = useTheme();
  //const imgFilter = isConnected ? 'drop-shadow(0px 0px 7px #80e27e )' : 'drop-shadow(0px 0px 7px #ff1744 )';
  return (
    <StyledCard>
        <CardHeader
        sx={{ backgroundColor: "rgba(0, 0, 0, 0.0)", color: theme.palette.primary.main }}  
        subheader={   <Typography pt={0.2} variant="h4"  style={{fontSize:"1.0rem"}}  component="div">
        Price: {formattedPrice}
      </Typography>}  
        title={<Typography style={{fontSize:"2.45rem"}}   variant="h1">Buy</Typography>}
      />
           <img src="media/Buy.png" alt="Voidrite"/>
      <CardContent sx={{ m:"-1vh"}} >  
          <Typography p={0.1} variant="body1" style={{fontSize:"0.75rem"}} component="p">
          Price is 4% more then mint price
        </Typography>
        <Typography p={0.3} variant="body1" style={{fontSize:"0.75rem"}} component="p">
          Voidrite Pool: {currentSupply}
        </Typography>
 
<Box mt={"1vh"}>
        <VoidriteBuyButton isConnected={isConnected} voidritePoolBalance={voidritePoolBalance} buyPrice={buyPrice} maxBuy={maxBuy}  />
      </Box>
      </CardContent>
    </StyledCard>
  );
};

export default VoidriteBuyCard;
