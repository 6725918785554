import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface Props {
    title: string,
    text: string,
}
  
const Terms = ({ title, text }: Props) => {
    const theme = useTheme();

    return (
        <Box sx={{ marginBottom: theme.spacing(3) }}>
            <Card sx={{ 
                 display: "flex", 
                 justifyContent: "center",
                 width: "90%", 
                 margin: "auto",
                padding: "2vw",
                backgroundColor: "rgba(555, 555, 555, 0.1)", 
                color: theme.palette.secondary.contrastText, 
                boxShadow: "0px 0px 15px #41b3a3", 
            }}>

                <CardContent>
                  <Box sx={{padding: "0.5vw"}}>
                    <Typography variant="h4" component="div">
                        {title}
                    </Typography> 
                    </Box>     
                    <Box sx={{ 
                        display: "flex", 
                        justifyContent: "center",
                        width: "85%", 
                        margin: "auto"
                    }}>
                        <Typography variant="body1" color="text.secondary.contrastText" sx={{textAlign: "left"}}>
                            {text}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}

export default Terms;

